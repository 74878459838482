import AccountActivate from "@/features/AccountActivate";
import ChangePassword from "@/features/ChangePassword";
import Billing from "@/features/Admin/Billing";
import CompaniesAdminPanel from "@/features/CompaniesAdminPanel";
import Dashboard from "@/features/Dashboard";
import Login from "@/features/Login";
import Profile from "@/features/Profile";
import ResetPassword from "@/features/ResetPassword";
import UsersAdminPanel from "@/features/UsersAdminPanel";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import RootLayout from "./components/RootLayout";

/**
 * This is where all of the routes for the app are defined.
 * Each endpoint is associated with its own feature/ component.
 */
export default function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <PageNotFound />,
      children: [
        // If the user removes the URL, redirect to the "index" route,
        // which in this case is '/dashboard'.
        // As the <Dashboard /> component is nested within <PrivateRoute />
        // the user will be redirected to <Login /> if they are not authenticated.
        {
          index: true,
          element: <Navigate to="/dashboard" replace />,
        },
        // Public Routes
        {
          path: "/login",
          element: <Login />,
        },
        // Public Routes
        {
          path: "/account-activate",
          element: <AccountActivate />,
        },
        {
          path: "/password",
          element: <ChangePassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        // Private Routes (i.e. routes that require authentication)
        {
          element: <PrivateRoute />,
          children: [
            {
              path: "/dashboard",
              element: <Dashboard />,
            },
            {
              path: "/user/account",
              element: <Profile />,
            },
            {
              path: "/admin/billing",
              element: <Billing />,
            },
            {
              path: "/admin/company",
              element: <CompaniesAdminPanel />,
            },
            {
              path: "/admin/user",
              element: <UsersAdminPanel />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
