import { themeColors } from "@/app/theme";
import { handleLogin } from "@/redux/authSlice";
import { useAppDispatch } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { defaultValues, FormData, schema } from "./schema";

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async (data: FormData) => {
    setApiErrorMessage("");
    const result = await dispatch(handleLogin(data));
    if (result.meta.requestStatus === "fulfilled") {
    } else {
      setApiErrorMessage("Invalid username or password, please try again");
    }
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };

  return (
    <Stack spacing={1} maxWidth={350}>
      <Stack alignItems={"center"} spacing={1}>
        <Typography variant="h1" fontWeight={"bold"}>
          Login
        </Typography>
        <Typography fontWeight={500} variant="h4">
          Sign in to your account to get started.
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleEnterPress}>
        <TextField
          {...register("email")}
          margin="normal"
          label="Email"
          error={!!errors.email}
          helperText={errors.email?.message}
          InputLabelProps={{ shrink: true }}
          size="small"
          autoComplete="email"
          autoFocus
          fullWidth
        />
        <TextField
          {...register("password")}
          margin="normal"
          label="Password"
          autoComplete="password"
          error={!!errors.password}
          type={showPassword ? "text" : "password"}
          helperText={errors.password?.message}
          fullWidth
          InputLabelProps={{ shrink: true }}
          size="small"
          FormHelperTextProps={{
            sx: { color: themeColors.error.primary, mt: 1 },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  color="secondary"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {apiErrorMessage && (
          <Typography variant="caption" color={themeColors.error.primary}>
            {apiErrorMessage}
          </Typography>
        )}
        <FormControlLabel
          sx={{ alignSelf: "flex-start" }}
          control={<Checkbox {...register("rememberMe")} checked={watch("rememberMe")} color="info" size="small" />}
          label={<Typography variant="bodyLight">Remember me</Typography>}
        />

        <Stack alignItems={"flex-end"}>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            size="small"
            variant="contained"
            color={"rfqDark"}
            sx={{ width: 120 }}
          >
            {/* Span protects against a bug involving google translate */}
            <span>Sign In</span>
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}
