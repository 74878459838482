import { fetchUser, selectAuthStatus, selectAuthToken, selectAuthUser } from "@/redux/authSlice";
import { useAppDispatch } from "@/store/hooks";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import ReAuthLoader from "./ReAuthLoader";

/**
 * Checks to see if the user is authenticated, and will redirect them to
 * the `/login` route if not.
 *
 * If redirected, the user's current route will be passed to the login
 * component as a prop (via the `useLocation` hook). This enables the app to
 * allow the user to continue where they left off, once they've signed in again.
 */
export default function PrivateRoute() {
  const dispatch = useAppDispatch();
  const token = useSelector(selectAuthToken);
  const user = useSelector(selectAuthUser);
  const status = useSelector(selectAuthStatus);
  const location = useLocation();

  if (!token || status === "failed") return <Navigate to="/login" state={{ from: location }} replace />;

  if (!user) {
    // This is neccessary because the `user` stored in Redux will not persist
    // on refresh, but the token will, so we should automatically attempt
    // to fetch the user info again with the same token.
    dispatch(fetchUser(token));
    // User is shown a loader with a "get out of jail free" button that goes to
    // the login screen if something goes wrong.
    return <ReAuthLoader />;
  }

  return <Outlet />;
}
