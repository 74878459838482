import App from "@/app/App";
import AppProvider from "@/app/AppProvider";
import "@/assets/fonts";
import * as Sentry from "@sentry/react";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import * as React from "react";
import * as ReactDOM from "react-dom/client";

ModuleRegistry.registerModules([AllCommunityModule]);

const SENTRY_TOKEN = import.meta.env["VITE_SENTRY_PROJECT_URL"];
const API_DOMAIN = /^https:\/\/app\.intick\.com\/api/;
// const TEST_DOMAIN = "localhost";
if (SENTRY_TOKEN) {
  Sentry.init({
    dsn: SENTRY_TOKEN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [API_DOMAIN],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
