import { useGetCompaniesQuery } from "@/redux/companiesApiSlice";
import Stack from "@mui/material/Stack";
import CompaniesTable from "./CompaniesTable";
import CreateCompanyForm from "./CreateCompanyForm";

export default function CreateCompany() {
  const { data } = useGetCompaniesQuery();

  if (!data) {
    return null;
  }
  return (
    <Stack sx={{ py: 3, px: 3 }} spacing={2} width="100%" alignItems="center">
      <CreateCompanyForm companies={data} />
      <CompaniesTable data={data} />
    </Stack>
  );
}
