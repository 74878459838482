import { themeColors, themeVariants } from "@/app/theme";
import ErrorCard from "@/components/ErrorCard";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import { setActiveRfq } from "@/redux/notificationSlice";
import { resetItems, setElectronicPrices, setPrice } from "@/redux/pricesSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import ClearIcon from "@mui/icons-material/Clear";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import FlagIcon from "@/components/FlagIcon";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { ParentOrderDTO, useGetParentOrderQuery } from "@/redux/parentApiSlice";
import { selectParent, setParentOrder } from "@/redux/parentSlice";
import { CANCEL_ORDER_LABEL, CLOSE_LABEL } from "@/translations";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { numericFormatter } from "react-number-format";
import { useSelector } from "react-redux";
import CancelParentOrder from "./CancelParentOrder";
import MoreDetails from "./MoreDetails";
import SendRfqDialog from "./SendRfqDialog";

export interface ParentOrderCardProps {
  order: ParentOrderDTO;
}

export default function ParentOrderCard(props: ParentOrderCardProps) {
  const { smallParentCard, isLargeOrUp, isMediumOrUp } = useBreakpoint();

  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);
  const instruments = useSelector(selectInstruments);

  const [isActive, setIsActive] = useState(selectedParent?.parent_order_id === props.order.parent_order_id);
  const [instrument, setLocalInstrument] = useState<InstrumentDTO | null>(null);
  const [isCancel, setIsCancel] = useState(false);
  const [rfqDialogOpen, setRfqDialogOpen] = useState(false);

  const {
    currentData: parentOrderResponse,
    error,
    isError,
    isLoading,
    isSuccess,
    refetch,
  } = useGetParentOrderQuery(props.order.parent_order_id, {
    pollingInterval: refetchTimeouts.fast,
  });

  // TODO: Use Redux state here instead of local state
  useEffect(() => {
    const item = instruments.find((i) => i.instrument_id === props.order.instrument_id);
    setLocalInstrument(item || null);
  }, [instruments]);

  // Should this be controlled by outside component or is this the responsibility of ParentOrderCard?
  useEffect(() => {
    setIsActive(selectedParent?.parent_order_id === props.order.parent_order_id);
    dispatch(resetItems());
  }, [selectedParent]);

  useEffect(() => {
    if (parentOrderResponse && parentOrderResponse.quantities) {
      // If the status of thi parent's child orders is any of that layed out in
      // the matureStates variable, then the parent is not cancellable.
      // const childStates = Object.keys(parentOrderResponse.quantities);
      // TODO:
      // setIsCancelable(childStates.every((childState) => !matureStates.includes(childState)));
    }
    if (isActive && parentOrderResponse) {
      // Make sure the store data for the selected parent is up-to-date.
      dispatch(setParentOrder(parentOrderResponse));
      dispatch(setPrice(null));
    }
  }, [parentOrderResponse]);

  // RFQ modal starts off closed
  const closeTooltip = !isActive ? CANCEL_ORDER_LABEL : CLOSE_LABEL;

  const handleClickPaper = () => {
    if (isCancel || !parentOrderResponse) return;
    dispatch(setParentOrder(isActive ? null : parentOrderResponse));
    // TODO handle price better in a more generic way
    // And the business logic of selecting a parent card should be better written
    dispatch(setPrice(null));
    if (!isActive) {
      // On select, set the underlying to == this parent's underlying.
      const poInstrument = instruments.find((i) => i.instrument_id === parentOrderResponse.instrument_id);
      dispatch(setInstrument(poInstrument || null));
      // and force the RFQ response state to be falsy.
      dispatch(setActiveRfq(null));
    } else {
      // On deselct, reset the selected price.
      dispatch(setPrice(null));
      // When a parent card is deselected, the instrument selection dropdown must default back to its null value.
      dispatch(setInstrument(null));
      // Force the electronic prices to reset.
      dispatch(
        setElectronicPrices({
          floor: 0,
          ceiling: 0,
        })
      );
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Causes button click to select its parent card but not deselect it if already selected.
    if (isActive) {
      event.stopPropagation();
    }
    setRfqDialogOpen(true);
  };

  const handleCloseRfqDialog = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setRfqDialogOpen(false);
  };

  const handleCancelOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isActive) {
      // Deselect the card to trigger the collapse animation
      handleClickPaper();
    } else {
      setIsCancel(true);
    }
  };

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading || !instrument) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess && parentOrderResponse) {
    const tradeable = (parentOrderResponse.quantities.tradable || 0).toString();

    const working = (parentOrderResponse.quantities.working || "---").toString();

    const matched = (parentOrderResponse.quantities.matched || "---").toString();
    // const currencySymbol = "£";
    return (
      <Paper
        data-testid={`parent-order-${props.order.parent_order_id}`}
        elevation={1}
        sx={{
          "borderRadius": isLargeOrUp ? 4 : 2,
          "border": !isActive ? `0.3px solid ${themeColors.border}` : null,
          "py": 1,
          "px": smallParentCard ? 1 : 2,
          "backgroundColor": isCancel
            ? themeColors.white.secondary
            : isActive
            ? themeVariants.selected.backgroundColor
            : themeVariants.unselected.backgroundColor,
          "color": isActive ? themeVariants.selected.color : themeVariants.unselected.color,
          "transitionDuration": 400,
          "transitionTimingFunction": "ease",
          "transitionProperty": "all",
          "cursor": "pointer",
          "&:hover": {
            backgroundColor: !isActive && !isCancel ? themeColors.white.secondary : null,
          },
        }}
        onClick={() => handleClickPaper()}
      >
        {isCancel ? (
          <CancelParentOrder handleClose={() => setIsCancel(false)} id={props.order.parent_order_id} />
        ) : smallParentCard ? (
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <FlagIcon width={40} height={30.4} instrumentId={instrument.instrument_id} />
              <Typography variant="h4Light" sx={{ flex: 1 }}>
                {instrument.name} - {numericFormatter(String(parentOrderResponse.quantity), { thousandSeparator: "," })}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography variant="caption">
                Remaining: {numericFormatter(String(tradeable), { thousandSeparator: "," })}
              </Typography>
              <Typography variant="caption">
                Working: {numericFormatter(String(working), { thousandSeparator: "," })}
              </Typography>
              <Typography variant="caption">
                Matched: {numericFormatter(String(matched), { thousandSeparator: "," })}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={{ height: 110 }} spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={1} direction="row" alignItems="center">
                <Box sx={{ display: isLargeOrUp ? "block" : "none" }}>
                  <FlagIcon width={40} height={30.4} instrumentId={instrument.instrument_id} />
                </Box>
                <Box>
                  <Typography variant="h4" sx={{ flex: 1 }}>
                    {instrument.name}
                  </Typography>
                  <Typography variant="bodyLight">{instrument.term}</Typography>
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                {isActive && (
                  <>
                    <Button
                      startIcon={<img src="./send-rfq.svg" />}
                      variant="outlined"
                      onClick={handleButtonClick}
                      color={isActive ? "rfqLight" : "rfqDark"}
                      sx={{ fontSize: 12, height: 25, minWidth: 10, paddingRight: 1 }}
                    >
                      {isLargeOrUp ? "Send RFQ" : null}
                    </Button>
                    <SendRfqDialog parent={parentOrderResponse} open={rfqDialogOpen} onClose={handleCloseRfqDialog} />
                  </>
                )}
                {/* TODO add rfq response company name */}
                {/* {!!parentOrderResponse?.rfq_company_name && (
                  <Typography variant="caption">{`Client: ${parentOrderResponse}`}</Typography>
                )} */}
                {/* {hasNotifications && (
                  <Badge role="notifications-badge" variant="dot" overlap="circular" color="secondary">
                    <NotificationsOutlinedIcon />
                  </Badge>
                )} */}

                <Tooltip title={closeTooltip} placement="top">
                  <IconButton onClick={handleCancelOrder} sx={{ height: 28, width: 28 }}>
                    <ClearIcon
                      sx={{
                        height: 20,
                        width: 20,
                        color: isActive ? themeVariants.selected.color : themeVariants.unselected.color,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between" direction="row">
              <Stack direction="row">
                <Stack justifyContent="space-between">
                  <Typography variant="bodyLight">Side</Typography>

                  <Typography variant={isMediumOrUp ? "h4" : "h5"}>
                    {capitalize(parentOrderResponse.direction)}
                  </Typography>
                </Stack>
                <Stack sx={{ ml: isMediumOrUp ? 4 : 1.5 }} justifyContent="space-between">
                  <Typography variant="bodyLight">Quantity</Typography>
                  <Typography variant={isMediumOrUp ? "h4" : "h5"}>
                    {numericFormatter(String(parentOrderResponse.quantity), {
                      thousandSeparator: ",",
                    })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <MoreDetails parent={parentOrderResponse} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Paper>
    );
  }

  return null;
}
