import z from "zod";

export const schema = z.object({
  first_name: z.string().min(2, { message: "First name must be at least 2 characters long" }),
  last_name: z.string().min(2, { message: "Last name must be at least 2 characters long" }),
  company_id: z.string().min(1, { message: "Company is required" }),
  email: z
    .string()
    .email({ message: "Email is required" })
    .min(5, { message: "Email must be at least 5 characters long" }),
  trader_role: z.boolean(),
  company_admin_role: z.boolean(),
  intick_admin_role: z.boolean(),
  active: z.boolean(),
});
export type FormData = z.infer<typeof schema>;

export const defaultValues = {
  first_name: "",
  last_name: "",
  company_id: "",
  email: "",
  trader_role: true,
  company_admin_role: false,
  intick_admin_role: false,
  active: true,
};
