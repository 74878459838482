import AppBar from "@/app/router/components/AppBar";
import ChatWithUsBtn from "@/features/Dashboard/ChatWithUsBtn";
import OrderBook from "@/features/OrderBook";
import SummaryBox from "@/features/Summary";
import { useGetInstrumentsQuery } from "@/redux/instrumentsApiSlice";
import { setInstruments } from "@/redux/instrumentSlice";
import { selectActiveRfq } from "@/redux/notificationSlice";
import { selectParent } from "@/redux/parentSlice";
import { useAppSelector } from "@/store/hooks";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CookieConsent from "../CookieConsent";
import MyOrdersList from "../MyOrdersList";
import ParentOrders from "../ParentOrdersList";
import InstrumentSelect from "./InstrumentSelect";

export default function Dashboard() {
  const dispatch = useDispatch();
  const selectedParent = useAppSelector(selectParent);
  const activeRfq = useAppSelector(selectActiveRfq);
  const { data } = useGetInstrumentsQuery();
  // Load instruments once before rendering the dashboard
  useEffect(() => {
    if (data?.length) {
      dispatch(setInstruments(data));
    } else {
      dispatch(setInstruments([]));
    }
  }, [data]);

  return (
    <>
      <Stack spacing={16}>
        <AppBar dashboard />
        <Grid container spacing={{ xs: 4, sm: 3, md: 2 }} sx={{ px: 2 }}>
          <Grid size={{ xs: 6, sm: 4 }}>
            <ParentOrders />
          </Grid>
          <Grid size={{ xs: 6, sm: 3 }}>
            <MyOrdersList />
          </Grid>
          <Grid size={{ xs: 12, sm: 5 }}>
            {!selectedParent && !activeRfq && (
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <InstrumentSelect />
              </Box>
            )}
            <OrderBook />
            <SummaryBox />
          </Grid>
        </Grid>
      </Stack>
      <CookieConsent />
      <Stack spacing={1.5} sx={{ position: "fixed", bottom: 16, left: 16 }}>
        {/*GUIDANCE AND PRICING CHART BUTTONS DISABLED FOR MVP*/}
        {/* <GuidanceBtn />
              <PricingChartBtn /> */}
        <ChatWithUsBtn />
      </Stack>
    </>
  );
}
