import ErrorCard from "@/components/ErrorCard";
import { selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import { useGetParentOrdersQuery } from "@/redux/parentApiSlice";
import { selectParent, setParentOrder } from "@/redux/parentSlice";
import { setPrice } from "@/redux/pricesSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import { Skeleton } from "@mui/material";
import { useEffect } from "react";
import AddOrderBtn from "./AddOrderBtn";
import GettingStarted from "./GettingStarted";
import ParentOrdersCarousel from "./ParentOrdersCarousel";

export default function ParentOrders() {
  const dispatch = useAppDispatch();
  const selectedParent = useAppSelector(selectParent);
  const instruments = useAppSelector(selectInstruments);

  const { currentData, error, isError, isLoading, isSuccess, refetch } = useGetParentOrdersQuery(undefined, {
    // Regularly refetch parent orders to receive those staged via InfoReach
    pollingInterval: refetchTimeouts.fast,
  });

  useEffect(() => {
    if (currentData?.length === 1 && currentData[0] && !selectedParent) {
      const instrument = instruments.find((x) => x.instrument_id === currentData[0]?.instrument_id);
      if (instrument) {
        dispatch(setPrice(null));
        dispatch(setParentOrder(currentData[0]));
        dispatch(setInstrument(instrument));
      }
    }
  }, [currentData, instruments]);

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess) {
    return currentData?.length ? (
      <ParentOrdersCarousel orders={currentData}>
        <AddOrderBtn isFirstOrder={false} />
      </ParentOrdersCarousel>
    ) : (
      <GettingStarted />
    );
  }

  return null;
}
