import { themeColors } from "@/app/theme";
import { InstrumentDTO } from "@/redux/instrumentsApiSlice";
import { selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import { Direction, PostParentOrderPayload, useCreateParentOrderMutation } from "@/redux/parentApiSlice";
import { setParentOrder } from "@/redux/parentSlice";
import { setPrice } from "@/redux/pricesSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { capitalize } from "lodash";
import { useRef, useState } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

interface Props {
  setOpen: (open: boolean) => void;
  open: boolean;
}

const CreateOrderModal = ({ setOpen, open }: Props) => {
  const dispatch = useAppDispatch();

  const instruments = useAppSelector(selectInstruments);
  const [direction, setDirection] = useState<Direction | "">("");
  const [localInstrument, setLocalInstrument] = useState<InstrumentDTO | null>(null);
  const [minBlockSize, setMinBlockSize] = useState(0);
  const [quantity, setQuantity] = useState<number | null | undefined>(undefined);
  // Quantity field defaults to 0, its error status depends on value being below min block size and having been edited.
  const isDefaultQuantity = useRef(true);

  const [createOrder, { error: submitError, isLoading: isLoadingSubmit, isError: isErrorSubmit }] =
    useCreateParentOrderMutation();

  // const { data: envData, error: envError, isError: isErrorEnv } = useGetEnvironmentQuery();
  // const termDates = `${envData?.["rolling-term-start"] ?? ""}${envData?.["rolling-term-end"] ?? ""}`;

  const handleChangeInstrument = (event: SelectChangeEvent) => {
    const instrument = instruments?.find((i) => i.instrument_id === event.target.value) || null;
    if (!instrument) return;
    setLocalInstrument(instrument);
    setMinBlockSize(instrument.min_quantity);
  };

  const handleChangeDirection = (event: SelectChangeEvent) => {
    setDirection(event.target.value as Direction);
  };
  const handleSubmit = async () => {
    const payload: PostParentOrderPayload = {
      direction: direction as Direction,
      instrument_id: localInstrument?.instrument_id as string,
      quantity: quantity as number,
    };
    try {
      const response = await createOrder(payload).unwrap();
      dispatch(setParentOrder(response));
      dispatch(setPrice(null));
      const instrument = instruments.find((x) => x.instrument_id === response.instrument_id);
      if (instrument) {
        dispatch(setInstrument(instrument));
      }

      handleClose();
    } catch (error) {
      console.log("[ AddOrderBtn ] handleSubmit ERROR: ", error);
    }
  };

  const InstrumentsInput = () => {
    if (!instruments.length) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 1,
            minWidth: 256,
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <FormControl sx={{ width: 250 }}>
        <InputLabel
          id="select-instrument"
          shrink
          sx={{
            "&.Mui-focused": { color: themeColors.active.primary },
          }}
        >
          Instrument
        </InputLabel>
        <Select
          labelId="select-instrument"
          id="instrument"
          value={localInstrument?.instrument_id || ""}
          label="Instrument"
          onChange={handleChangeInstrument}
          size={"small"}
          displayEmpty
          renderValue={(value) =>
            value === "" ? (
              <Typography color={themeColors.text.disabled}>Please select</Typography>
            ) : (
              `${localInstrument?.name} ${localInstrument?.term}`
            )
          }
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: themeColors.active.primary,
            },
            ".MuiSelect-icon": {
              color: localInstrument === null ? themeColors.text.disabled : null,
            },
          }}
        >
          {instruments.map((instrument, i) => (
            <MenuItem key={i} value={instrument.instrument_id}>
              {instrument.name} {instrument.term}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const ErrorMessage = () => {
    if (isErrorSubmit && submitError && "data" in submitError) {
      return (
        <Alert severity="error" sx={{ flex: 1 }}>
          {`[${submitError.status}]: ${JSON.stringify(submitError.data)}`}
        </Alert>
      );
    }
    return null;
  };

  const selectQuantityError = () => {
    if (quantity === undefined) {
      return false;
    }
    if (quantity === null || quantity < minBlockSize) {
      return true;
    }
    return false;
  };

  const isErrorQuantity = selectQuantityError();

  const handleClose = () => {
    setOpen(false);
  };

  const handleQuantityChange = (values: NumberFormatValues) => {
    if (values.floatValue !== null && values.floatValue !== undefined) {
      setQuantity(values.floatValue);
      isDefaultQuantity.current = false;
    } else {
      setQuantity(null);
    }
  };

  const isSubmitDisabled = () => {
    if (!localInstrument || !quantity || quantity < minBlockSize || !direction) {
      return true;
    }
    return false;
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { maxWidth: "none" },
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          handleSubmit();
        },
      }}
    >
      <DialogTitle sx={{ pb: 0 }}>
        <Typography variant="h2Light" color={themeColors.black.secondary}>
          Create order
        </Typography>
        <Typography variant="bodyLight" display="block" color={themeColors.black.secondary}>
          Please complete the fields below.
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 12,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <Stack direction="row" spacing={2} mt={2}>
          <FormControl sx={{ width: 152 }}>
            <InputLabel
              id="select-direction"
              shrink
              sx={{
                "&.Mui-focused": { color: themeColors.active.primary },
              }}
            >
              Direction
            </InputLabel>
            <Select
              labelId="select-direction"
              id="direction"
              value={direction}
              label="Direction"
              onChange={handleChangeDirection}
              size={"small"}
              displayEmpty
              renderValue={(value) =>
                (value as string) === "" ? (
                  <Typography color={themeColors.text.disabled}>Please select</Typography>
                ) : (
                  capitalize(value)
                )
              }
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: themeColors.active.primary,
                },
                ".MuiSelect-icon": {
                  color: direction === "" ? themeColors.text.disabled : null,
                },
              }}
            >
              <MenuItem value={Direction.BUY}>Buy</MenuItem>
              <MenuItem value={Direction.SELL}>Sell</MenuItem>
            </Select>
          </FormControl>
          <InstrumentsInput />
          <NumericFormat
            value={quantity}
            onValueChange={handleQuantityChange}
            thousandSeparator
            customInput={TextField}
            size={"small"}
            label="Quantity"
            id="quantity"
            helperText={`The minimum block size is ${minBlockSize}.`}
            // Textfield has error status only when it is below block size due to a value being entered, the field
            // defaults to having a value of 0 which should not cause error status.
            error={isErrorQuantity}
            sx={{
              "width": 164,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: isErrorQuantity ? themeColors.error.primary : themeColors.active.primary,
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: isErrorQuantity ? themeColors.error.primary : themeColors.active.primary,
              },
              "& .MuiFormHelperText-root": {
                color: isErrorQuantity ? themeColors.error.primary : "transparent",
              },
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <ErrorMessage />
        <LoadingButton
          loading={isLoadingSubmit}
          disabled={isSubmitDisabled()}
          type="submit"
          variant="contained"
          data-testid="submit-parent-order"
          sx={{ mr: 2, mb: 3, mt: 1, borderRadius: 2, fontSize: 18 }}
        >
          {/* Span protects against a bug involving google translate */}
          <span>Done</span>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateOrderModal;
