import z from "zod";

export const schema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters long" }),
  full_name: z.string().min(2, { message: "Full name must be at least 4 characters long" }),
  trade_confirmation_email: z
    .string()
    .email({ message: "Trade confirmation email is required" })
    .min(5, { message: "Email must be at least 5 characters long" }),
  risk_desk: z.boolean(),
  default_broker: z.string().min(1, { message: "Broker is required" }),
});
export type FormData = z.infer<typeof schema>;

export const defaultValues = {
  name: "",
  full_name: "",
  trade_confirmation_email: "",
  risk_desk: true,
  default_broker: "",
};
