const CANCEL_ORDER_LABEL = "Cancel order";
const CLOSE_LABEL = "Close";
const BEST_ASK_LABEL = "Best ask";
const BEST_BID_LABEL = "Best bid";
const ELECTRONIC_PRICE_LABEL = "Electronic price";
const TERMS_AND_CONDITIONS_LABEL = "By creating an account and signing in you are agreeing to our ";
export {
  BEST_ASK_LABEL,
  BEST_BID_LABEL,
  CANCEL_ORDER_LABEL,
  CLOSE_LABEL,
  ELECTRONIC_PRICE_LABEL,
  TERMS_AND_CONDITIONS_LABEL,
};
