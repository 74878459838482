import { OrderState } from "@/redux/ordersApiSlice";
import { Direction } from "@/redux/parentApiSlice";
import { RfqResponseState } from "@/redux/rfqApiSlice";
import { createTheme } from "@mui/material/styles";

/**
 * InTick brand colours. US spelling is just a code convention.
 * Constant defined because it's less of a mouthful than typing out
 * `theme.palette.primary.main` all the time.
 */
export const themeColors = {
  logo: {
    primary: "#2C386C",
    secondary: "#556DAA",
    tertiary: "#7369FD",
  },
  text: {
    primary: "#393939",
    secondary: "#5A5A5A",
    disabled: "#999999",
  },
  white: {
    primary: "#FFFFFF",
    secondary: "#F9F8F5",
    tertiary: "#FCFCFC",
    quaternary: "#F9F9F9",
    disabled: "#F3F3F3",
  },
  black: {
    primary: "#393939",
    secondary: "#5A5A5A",
    tertiary: "#D8D8D8",
  },
  buttons: {
    chatWithUs: "#D9E9B7",
    guidance: "#1479F5",
    pricingChart: "#F24B0F",
  },
  border: "#C0C0C0",
  prices: {
    [Direction.SELL]: "#E05E57",
    [Direction.BUY]: "#3D419C",
  },
  state: {
    [OrderState.Working]: "#AAE2EE",
    [RfqResponseState.Pending]: "#AAE2EE",
    [OrderState.AwaitingMatch]: "#F1CCB7",
    [OrderState.Matched]: "#FFF2D0",
    [OrderState.PartialBrokerAccepted]: "#FFF2D0",
    [OrderState.BrokerAccepted]: "#D9E9B7",
    [OrderState.PartialBrokerFilled]: "#D9E9B7",
    [OrderState.BrokerFilled]: "#05812E",
    [OrderState.Error]: "#940E07",
    [OrderState.Cancelled]: "#F3EAEA",
    [OrderState.Expired]: "#F3EAEA",
    [OrderState.Rejected]: "#940E07",
    [OrderState.AwaitingRfqAcceptance]: "#F1CCB7",
  },
  success: {
    primary: "#05812E",
    secondary: "#D9E9B7",
  },
  active: {
    primary: "#0B79D0",
    secondary: "#F4F7F9",
  },
  error: {
    primary: "#940E07",
    secondary: "#F3EAEA",
  },
  popup: {
    backgroundColor: "#F5F5F5",
  },
};

/**
 * Enables consistent setting of colours for selected/
 * unselected variants of components.
 */
export const themeVariants = {
  selected: {
    backgroundColor: themeColors.black.primary,
    color: themeColors.white.primary,
  },
  unselected: {
    backgroundColor: themeColors.white.primary,
    color: themeColors.text.primary,
  },
};

const theme = createTheme({
  // Added for responsive designs
  breakpoints: {
    values: {
      xs: 600,
      sm: 800,
      md: 1200,
      lg: 1400,
      xl: 1920,
    },
  },

  palette: {
    primary: {
      main: themeColors.logo.primary,
    },
    secondary: {
      main: themeColors.logo.secondary,
    },
    background: {
      default: themeColors.white.tertiary,
    },
    warning: {
      main: themeColors.error.secondary,
    },
    success: {
      main: themeColors.success.primary,
    },
    error: {
      main: themeColors.error.primary,
    },
    info: {
      main: themeColors.active.primary,
    },
    text: {
      primary: themeColors.text.primary,
      secondary: themeColors.text.secondary,
      disabled: themeColors.text.disabled,
    },
  },
  typography: {
    fontFamily: `"Inter", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: "2.125rem",
      fontWeight: 600,
      lineHeight: "130%",
      letterSpacing: 1,
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "130%",
      letterSpacing: 1,
    },
    h2Light: {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: "130%",
      letterSpacing: 1,
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "140%",
      letterSpacing: 0.7,
    },
    h3Light: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: 0.7,
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: "140%",
      letterSpacing: 0.63,
    },
    h4Light: {
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: 0.63,
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "140%",
      letterSpacing: -0.3,
    },
    h5Light: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: -0.5,
    },
    body: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "130%",
      letterSpacing: 0.5,
    },
    bodyLight: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "130%",
      letterSpacing: 0.5,
    },
    caption: {
      fontSize: "0.625rem",
      fontWeight: 600,
      lineHeight: "140%",
      letterSpacing: 0.5,
    },
    captionLight: {
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "130%",
      letterSpacing: 0.3,
    },
    link: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: "130%",
      letterSpacing: 0.5,
    },
    microLink: {
      fontSize: "0.5rem",
      fontWeight: 400,
      textDecoration: "underline",
      lineHeight: "130%",
      letterSpacing: 0.5,
    },
    micro: {
      fontSize: "0.5rem",
      fontWeight: 400,
      lineHeight: "130%",
      letterSpacing: 0.5,
    },
    numeric_h4: {
      fontFamily: `"Poppins", "Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    numeric_h5: {
      fontFamily: `"Poppins", "Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    numeric_body1: {
      fontFamily: `"Poppins", "Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const augmentedTheme = createTheme(theme, {
  palette: {
    chatWithUs: theme.palette.augmentColor({
      color: {
        main: themeColors.buttons.chatWithUs,
      },
      name: "chatWithUs",
    }),
    guidance: theme.palette.augmentColor({
      color: {
        main: themeColors.buttons.guidance,
      },
      name: "guidance",
    }),
    pricingChart: theme.palette.augmentColor({
      color: {
        main: themeColors.buttons.pricingChart,
      },
      name: "pricingChart",
    }),
    rfqLight: theme.palette.augmentColor({
      color: {
        main: themeColors.black.tertiary,
      },
      name: "rfqLight",
    }),
    rfqDark: theme.palette.augmentColor({
      color: {
        main: themeColors.text.primary,
      },
      name: "rfqDark",
    }),
    [Direction.SELL]: theme.palette.augmentColor({
      color: {
        main: themeColors.prices[Direction.SELL],
        contrastText: themeColors.white.primary,
      },
      name: Direction.SELL,
    }),
    [Direction.BUY]: theme.palette.augmentColor({
      color: {
        main: themeColors.prices[Direction.BUY],
      },
      name: Direction.BUY,
    }),
    dark: theme.palette.augmentColor({
      color: {
        main: themeColors.black.primary,
      },
      name: "dark",
    }),
  },
});

export default augmentedTheme;

// -----------------------Pallete type overrides-------------------------------
declare module "@mui/material/styles" {
  interface Palette {
    chatWithUs: Palette["primary"];
    guidance: Palette["primary"];
    pricingChart: Palette["primary"];
    [Direction.SELL]: Palette["primary"];
    [Direction.BUY]: Palette["primary"];
    rfqLight: Palette["primary"];
    rfqDark: Palette["primary"];
    dark: Palette["primary"];
  }

  interface PaletteOptions {
    chatWithUs?: PaletteOptions["primary"];
    guidance?: PaletteOptions["primary"];
    pricingChart?: PaletteOptions["primary"];
    [Direction.SELL]?: PaletteOptions["primary"];
    [Direction.BUY]?: PaletteOptions["primary"];
    rfqLight?: PaletteOptions["primary"];
    rfqDark?: PaletteOptions["primary"];
    dark?: PaletteOptions["primary"];
  }
}

// ---------------------Typography type overrides-----------------------------
declare module "@mui/material/styles" {
  interface TypographyVariants {
    body: React.CSSProperties;
    h2Light: React.CSSProperties;
    h3Light: React.CSSProperties;
    h4Light: React.CSSProperties;
    h5Light: React.CSSProperties;
    bodyLight: React.CSSProperties;
    captionLight: React.CSSProperties;
    microLink: React.CSSProperties;
    micro: React.CSSProperties;
    link: React.CSSProperties;
    numeric_h4: React.CSSProperties;
    numeric_h5: React.CSSProperties;
    numeric_body1: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body: React.CSSProperties;
    h2Light: React.CSSProperties;
    h3Light: React.CSSProperties;
    h4Light: React.CSSProperties;
    h5Light: React.CSSProperties;
    bodyLight: React.CSSProperties;
    captionLight: React.CSSProperties;
    microLink: React.CSSProperties;
    micro: React.CSSProperties;
    link: React.CSSProperties;
    numeric_h4?: React.CSSProperties;
    numeric_h5?: React.CSSProperties;
    numeric_body1?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body: true;
    h2Light: true;
    h3Light: true;
    h4Light: true;
    h5Light: true;
    bodyLight: true;
    captionLight: true;
    microLink: true;
    micro: true;
    link: true;
    numeric_h4: true;
    numeric_h5: true;
    numeric_body1: true;
  }
}

// -----------------------Button type overrides-------------------------------
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    chatWithUs: true;
    guidance: true;
    pricingChart: true;
    rfqLight: true;
    rfqDark: true;
    [Direction.SELL]: true;
    [Direction.BUY]: true;
    dark: true;
  }
}

// ------------------------Chip type overrides--------------------------------
declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    [Direction.BUY]: true;
    [Direction.SELL]: true;
  }
}
