import { CompanyDTO } from "@/redux/companiesApiSlice";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component

interface RowData {
  company_id: string;
  name: string;
  full_name: string;
  roles: string;
  brokers: string;
  default_broker: string;
  created_at: string;
  updated_at: string;
}

const columnDefs = [
  { field: "company_id" },
  { field: "name" },
  { field: "full_name" },
  { field: "roles" },
  { field: "brokers" },
  { field: "default_broker" },
  { field: "created_at" },
  { field: "updated_at" },
] as ColDef<RowData>[];

const selectRowData = (companies: CompanyDTO[]) =>
  companies.map((company) => ({
    company_id: company.company_id,
    name: company.name,
    full_name: company.full_name,
    account: company.account,
    roles: company.roles ? company.roles.map((r) => r.toString()).join(", ") : "",
    brokers: company.brokers.map((b) => b.name).join(", ") || "",
    default_broker: company.default_broker?.name || "",
    created_at: company.created_at,
    updated_at: company.updated_at,
  }));

interface Props {
  data: CompanyDTO[];
}

const CompaniesTable = ({ data }: Props) => {
  return (
    <Stack sx={{ height: 1000, width: "100%" }} spacing={1}>
      <Typography variant="h3">Companies</Typography>
      <AgGridReact rowData={selectRowData(data)} columnDefs={columnDefs} />
    </Stack>
  );
};

export default CompaniesTable;
