import { themeColors } from "@/app/theme";
import { useMenu } from "@/hooks/useMenu";
import { selectAuthUser } from "@/redux/authSlice";
import { MatchRequestDTO } from "@/redux/matchApiSlice";
import { useGetMatchRequestsQuery } from "@/redux/matchRequestApiSlice";
import { setOrderMatchRequests } from "@/redux/matchSlice";
import { isMatchRequest, isRfq, selectActiveNotification, setActiveNotification } from "@/redux/notificationSlice";
import { RfqDTO, useGetRfqsQuery } from "@/redux/rfqApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import dayjs from "@/utils/Time";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import NotificationDetails from "./NotificationDetails";
import NotificationsTab from "./NotificationsTab";
import { useGetUserCompanyQuery } from "@/redux/companiesApiSlice";

export enum TabType {
  MATCH_REQUESTS = "Notifications",
  RFQS = "Requests",
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * Handles hiding/ showing the tab contents when the tab index changes.
 */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Handles adding accessibility props to the MUI Tabs.
 */
function a11yProps(index: number) {
  return {
    "id": `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function NotificationsMenu() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectAuthUser);
  const activeNotification = useAppSelector(selectActiveNotification);

  const [tabIndex, setTabIndex] = useState(0);
  const [matchRequestNotifications, setMatchRequestNotifications] = useState<MatchRequestDTO[]>([]);
  const [rfqNotifications, setRfqNotifications] = useState<RfqDTO[]>([]);
  const [activeNotificationData, setActiveNotificationData] = useState(activeNotification);
  const { anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose } = useMenu();
  const bellIconRef = useRef<HTMLButtonElement>(null);

  const { currentData: myCompany } = useGetUserCompanyQuery(user?.company_id || "", {
    skip: !user?.company_id,
  });

  const {
    currentData: allMatchRequests,
    isFetching: isFetchingMatchRequest,
    refetch: refetchMatchRequest,
  } = useGetMatchRequestsQuery(undefined, {
    // Regularly refetch match requests to passively check for updates.
    pollingInterval: refetchTimeouts.fast,
  });

  const {
    currentData: allRfqs,
    isFetching: isFetchingRfq,
    refetch: refetchRfq,
  } = useGetRfqsQuery(undefined, {
    pollingInterval: refetchTimeouts.fast,
  });

  useEffect(() => {
    // As the API sends the deadline as "seconds-to-expiry", we need to
    // regularly re-fetch the notifications whenever the UI updates.
    // E.g. when switching tabs, or navigating to/ from from the detail view.
    if (isMenuOpen) {
      refetchMatchRequest();
      refetchRfq();
    }
  }, [isMenuOpen, tabIndex, activeNotification]);

  useEffect(() => {
    dispatch(setOrderMatchRequests(allMatchRequests || []));
    if (allMatchRequests?.length && user) {
      const notCreatedByMe = allMatchRequests.filter(
        (x) => x.created_by_user_id !== user.user_id && x.expires_in_seconds > 0
      );
      const difference = notCreatedByMe.filter(
        (x) => !matchRequestNotifications.some((y) => x.order_match_request_id === y.order_match_request_id)
      );
      // Notifications changed
      if (difference.length > 0) {
        setMatchRequestNotifications(notCreatedByMe);
      }
    } else {
      setMatchRequestNotifications([]);
    }
  }, [allMatchRequests, user]);

  useEffect(() => {
    if (allRfqs?.length && user && myCompany) {
      const notCreatedByMe = allRfqs.filter(
        (x) =>
          x.created_by_company_name !== myCompany.name &&
          x.expires_in_seconds > 0 &&
          (!x.responses_ids || x.responses_ids.length === 0)
      );
      const newNotifications = notCreatedByMe.filter((x) => !rfqNotifications.some((y) => x.rfq_id === y.rfq_id));
      const deletedNotifications = rfqNotifications.filter((x) => !notCreatedByMe.some((y) => x.rfq_id === y.rfq_id));
      // Notifications changed
      if (newNotifications.length > 0 || deletedNotifications.length > 0) {
        setRfqNotifications(notCreatedByMe);
      }
    } else {
      setRfqNotifications([]);
    }
  }, [allRfqs, user, myCompany]);

  useEffect(() => {
    // As a consequence of the above ^ we need to ensure that the data being
    // passed into the detail view is the most up-to-date available, in order
    // to esnure that the countdown timers are syncronised.
    if (activeNotification === null) {
      setActiveNotificationData(null);
    } else {
      if (isMatchRequest(activeNotification)) {
        setActiveNotificationData(
          matchRequestNotifications?.find((n) => {
            return n.order_match_request_id === activeNotification.order_match_request_id;
          }) || null
        );
      }
      if (isRfq(activeNotification)) {
        setActiveNotificationData(
          rfqNotifications?.find((n) => {
            return n.rfq_id === activeNotification.rfq_id;
          }) || null
        );
      }
    }
  }, [matchRequestNotifications, rfqNotifications, activeNotification]);

  // If the menu is closed, we need to automatically set the selected notification
  // to the most recent notification. This ensures that the notification details
  // will be presented by default.
  useEffect(() => {
    if (matchRequestNotifications && rfqNotifications) {
      const allData = [...(matchRequestNotifications || []), ...(rfqNotifications || [])];
      const mostRecent = allData.length
        ? allData.reduce(function (prev, current) {
            return prev && prev.created_at > current.created_at ? prev : current;
          })
        : undefined;
      if (!isMenuOpen && !!mostRecent) {
        // Make sure the most recent notification will appear automatically.
        dispatch(setActiveNotification(mostRecent));
        if (isMatchRequest(mostRecent)) {
          setTabIndex(0);
        }
        if (isRfq(mostRecent)) {
          setTabIndex(1);
        }
        // Open the menu if the most recent notification was created within the
        // refresh timeout of the API calls (15s).
        const now = Math.round(new Date().getTime() / 1000);
        const createdAt = Math.round(new Date(mostRecent.created_at).getTime());
        if (now - createdAt <= refetchTimeouts.fast / 1000 && !!bellIconRef.current) {
          bellIconRef.current.click();
        }
      }
    }
  }, [matchRequestNotifications, rfqNotifications]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const now = dayjs();

  const menuId = "notifications-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      slotProps={{
        paper: {
          style: {
            backgroundColor: themeColors.white.tertiary,
          },
        },
        root: {
          slotProps: {
            backdrop: {
              style: {
                backgroundColor: "rgba(255,255,255, 0.9)",
              },
            },
          },
        },
      }}
    >
      <ClickAwayListener onClickAway={() => handleMenuClose()}>
        <Stack width={432} sx={{ pt: 1, pb: 1 }}>
          {/* Title and Close Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            <Tooltip title="Close" placement="top">
              <IconButton onClick={handleMenuClose}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Time and Date */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
            }}
          >
            <Typography variant="caption">{`${now.format("HH:mm")}`}</Typography>
            <Typography variant="caption">{`${now.format("DD/MM/YY")}`}</Typography>
          </Box>

          {/* div prevents button from stretching to full width of parent container */}
          <Box sx={{ pl: 1 }}>
            <Button
              sx={{ ml: 1, color: themeColors.black.secondary, fontSize: 14, px: 0 }}
              startIcon={<ChevronLeftIcon />}
              onClick={() => dispatch(setActiveNotification(null))}
            >
              Back to list view
            </Button>
          </Box>

          {activeNotificationData === null ? (
            // Notifications / Requests Tabs
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="notifcations tabs"
              >
                <Tab sx={{ ml: 2, textTransform: "none" }} label={TabType.MATCH_REQUESTS} {...a11yProps(0)} />
                <Tab sx={{ textTransform: "none" }} label={TabType.RFQS} {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={tabIndex} index={0}>
                <NotificationsTab
                  notifications={matchRequestNotifications}
                  type={TabType.MATCH_REQUESTS}
                  isFetching={isFetchingMatchRequest}
                  refetch={refetchMatchRequest}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={1}>
                <NotificationsTab
                  notifications={rfqNotifications}
                  type={TabType.RFQS}
                  isFetching={isFetchingRfq}
                  refetch={refetchRfq}
                />
              </CustomTabPanel>
            </Box>
          ) : (
            <NotificationDetails notification={activeNotificationData} handleClose={handleMenuClose} />
          )}
          {/* Bottom circle icon */}
          <Box display="flex" justifyContent="center">
            <CircleIcon color="disabled" sx={{ fontSize: 12 }} />
          </Box>
        </Stack>
      </ClickAwayListener>
    </Menu>
  );

  return (
    <>
      <IconButton
        size="large"
        color="primary"
        aria-label="notifications-bell"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        ref={bellIconRef}
      >
        <Badge
          role="notifications-badge"
          variant="dot"
          overlap="circular"
          color="secondary"
          invisible={!matchRequestNotifications?.length && !rfqNotifications?.length}
        >
          <NotificationsOutlinedIcon sx={{ fontSize: 30 }} />
        </Badge>
      </IconButton>
      {renderMenu}
    </>
  );
}
