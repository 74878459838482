import { baseApi } from "@/config/BaseQuery";
import moment from "moment";

export interface BillingDTO {
  company_name: string;
  first_name: string;
  last_name: string;
  user_id: string;
  broker_name: string;
  broker_id: string;
  match_request_id: string;
  order_id: string;
  payment_order_id: string;
  match_request_created_at: string;
  creation_date: string;
  creation_time: string;
  instrument_name: string;
  bbg_code: string;
  exchange_instrument_id: string;
  exchange: string;
  exchange_iso_code: string;
  tick_size: number;
  term: string;
  direction: string;
  quantity: number;
  price: number;
}

const billingApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBillingData: build.query<BillingDTO[], void>({
      query: () => `/billing`,
      transformResponse: (response: BillingDTO[]) => {
        return response.map((billing) => {
          const createdAt = moment(new Date(billing.match_request_created_at));
          billing.creation_date = createdAt.format("D MMM yyy");
          billing.creation_time = createdAt.format("HH:mm:ss z");
          return billing;
        });
      },
      // query: ({company_id, date_from, date_to}) => {
      //   const params = new URLSearchParams({});
      //
      //   for (const val of company_id) {
      //     params.append("company_id", val);
      //   }
      //
      //   for (const val of date_from) {
      //     params.append("date_from", val);
      //   }
      //
      //   for (const val of date_to) {
      //     params.append("date_to", val);
      //   }
      //
      //   return {
      //     url: `/billing?` + params.toString(),
      //   };
      // },
    }),
  }),
});

export const { useGetBillingDataQuery } = billingApiSlice;
