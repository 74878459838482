import theme, { themeColors } from "@/app/theme";
import EditAmountDialog from "@/features/OfferCard/EditAmountDialog";
import { selectActiveRfq } from "@/redux/notificationSlice";
import { OrderDTO } from "@/redux/ordersApiSlice";
import { Direction } from "@/redux/parentApiSlice";
import { selectParent } from "@/redux/parentSlice";
import { addItem, removeItem, resetItems, selectItems } from "@/redux/pricesSlice";
import { RfqResponseDTO } from "@/redux/rfqApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { metricFormatter } from "@/utils/String";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import { useEffect, useState } from "react";
import { numericFormatter } from "react-number-format";

export interface OfferCardProps {
  order: OrderDTO | RfqResponseDTO;
  isPriceSelected: boolean;
  onClick: (price: number) => void;
}

export default function OfferCard(props: OfferCardProps) {
  const isRfqResponse = props.order.hasOwnProperty("parent_order_id");
  const selectedParent = useAppSelector(selectParent);
  const activeRfq = useAppSelector(selectActiveRfq);
  const dispatch = useAppDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const prices = useAppSelector(selectItems);
  const isWorkingOrder = selectedParent?.parent_order_id === (props.order as OrderDTO).parent_order_id;
  const [orderQuantity, setOrderQuantity] = useState(props.order.quantity);

  useEffect(() => {
    setOrderQuantity(props.order.quantity);
  }, [props.order.quantity]);

  const isSelectable =
    (!!selectedParent &&
      activeRfq === null &&
      (selectedParent.direction === Direction.BUY
        ? props.order.direction === Direction.SELL
        : props.order.direction === Direction.BUY)) ||
    isWorkingOrder;

  const isEditable = isSelectable && (props.order as OrderDTO).parent_order_id !== selectedParent?.parent_order_id;

  useEffect(
    () => () => {
      // Reset on unmount
      dispatch(resetItems());
    },
    []
  );

  useEffect(() => {
    if (!props.isPriceSelected || !!activeRfq) {
      // Force deslection if deselcted the price or responding to an RFQ.
      setIsSelected(false);
    }
  }, [props.isPriceSelected, activeRfq]);

  useEffect(() => {
    // Todo refactor to use selected from redux purely
    if (prices.length === 0) {
      setIsSelected(false);
    }
  }, [prices]);

  const CustomInput = styled(TextField)(() => ({
    "input": {
      cursor: isSelectable ? "pointer" : "default",
      fontWeight: 500,
      fontSize: isSelected ? 16 : 18,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
      },
      "&:hover fieldset": {
        border: 0,
      },
      "&.Mui-focused fieldset": {
        border: 0,
      },
    },
    "& .MuiFormLabel-root": {
      backgroundColor: isSelected
        ? themeColors.success.secondary
        : isRfqResponse
        ? (themeColors.state as any)[props.order.state]
        : themeColors.white.primary,
      borderRadius: "4px 4px 0 0",
      padding: "0 4px",
      // marginTop: "-10px",
      zIndex: 0, // Stops the labels appearing over the summary box
    },
    "& .MuiInputBase-input": {
      padding: isEditable && isSelected ? "16px 16px 12px 24px" : "16px 16px 16px 24px",
    },
  }));

  const CustomAccordionSummary = styled(AccordionSummary)(() => ({
    "&.MuiAccordionSummary-root": {
      "&.Mui-focusVisible": {
        backgroundColor: "inherit",
        borderRadius: "0.5rem",
      },
      "&.MuiButtonBase-root": {
        cursor: isSelectable ? "pointer" : "default",
      },
    },
  }));

  const [amountDialogOpen, setAmountDialogOpen] = useState(false);

  useEffect(() => {
    if (amountDialogOpen) {
      // Reset the quantity to the full amount each time the dialog is opened.
      setOrderQuantity(props.order.quantity);
    }
  }, [amountDialogOpen]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isSelectable) {
      if (isSelected) {
        dispatch(removeItem(props.order));
      } else {
        dispatch(addItem({ ...props.order, quantity: orderQuantity }));
      }
      setIsSelected(!isSelected);
      if (!props.isPriceSelected) {
        // Allows us to directly select an order (before selecting the price).
        props.onClick(props.order.limit_price);
      }
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAmountDialogOpen(true);
  };

  const handleCloseAmountDialog = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAmountDialogOpen(false);
  };

  const customInputProps = {
    label: isRfqResponse ? `${(props.order as RfqResponseDTO).company_name}` : "",
    defaultValue: !isSelected
      ? metricFormatter(orderQuantity, 1)
      : numericFormatter(orderQuantity.toString(), {
          thousandSeparator: true,
        }),
    onClick: handleClick,
  };

  return (
    <Box id="offer-card" sx={{ position: "relative", width: 200 }}>
      {isRfqResponse && (
        <Typography
          variant="body"
          sx={{
            background: !isSelected ? "#ffff" : themeColors.success.secondary,
            position: "absolute",
            top: -8,
            right: 7,
            zIndex: 100,
          }}
        >
          RFQ
        </Typography>
      )}
      <Accordion
        elevation={0}
        expanded={isSelected && !isWorkingOrder}
        sx={{
          "boxShadow": 1,
          "border": 1,
          "borderColor": isSelected
            ? isWorkingOrder
              ? themeColors.black.primary
              : themeColors.success.primary
            : (theme.palette as any)[props.order.direction as any].main,
          "p": 0,
          "&:hover": {
            borderColor: (theme.palette as any)[props.order.direction as any].dark,
          },
          "backgroundColor": isSelected
            ? isWorkingOrder
              ? themeColors.active.secondary
              : themeColors.success.secondary
            : isWorkingOrder
            ? themeColors.state.WORKING
            : themeColors.white.primary,
        }}
        disableGutters
        onClick={handleClick}
      >
        <CustomAccordionSummary
          aria-controls="price-content"
          id="price-header"
          sx={{
            "p": 0,
            ".MuiAccordionSummary-content": {
              margin: 0,
              // alignItems: "center",
            },
          }}
        >
          {/* Offer Quantity */}
          <CustomInput
            {...customInputProps}
            inputProps={{
              readOnly: true,
            }}
          />
          {/* Residual & Hit Rate */}
          <Stack direction="row" spacing={1} sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClick}>
            <Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  height: "24px",
                  backgroundColor: "border",
                }}
              />
            </Box>
            <Stack spacing={1}>
              <Typography variant="captionLight">{`Residual: ${
                (props.order as OrderDTO).residual ? "Yes" : "No"
              }`}</Typography>
              <Typography variant="captionLight">
                {/* TODO: Defaulted to 100% hit rate for now */}
                {isRfqResponse ? "Auto-accept" : "Hit Rate: 100%"}
              </Typography>
            </Stack>
          </Stack>
        </CustomAccordionSummary>
        <AccordionDetails
          sx={{
            pt: 0,
            pb: 0.5,
            px: 0,
            cursor: isSelectable ? "pointer" : "default",
          }}
        >
          <Stack>
            <Box sx={{ px: 2 }}>
              {isEditable ? (
                <Button
                  sx={{
                    fontSize: 11,
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                  onClick={handleButtonClick}
                >
                  Edit amount
                </Button>
              ) : null}
              <EditAmountDialog
                open={amountDialogOpen}
                onClose={handleCloseAmountDialog}
                order={props.order}
                orderQuantity={orderQuantity}
                setOrderQuantity={setOrderQuantity}
                isOrderSelected={isSelected}
              />
              <Divider sx={{ backgroundColor: "border" }} />
            </Box>
            <Typography variant="micro" sx={{ textAlign: "center" }}>
              Accept Time: 0 mins
            </Typography>
            <Typography variant="micro" sx={{ textAlign: "center" }}>
              Median Accept Time: 0 mins
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
