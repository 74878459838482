import { themeColors } from "@/app/theme";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import CreateOrderModal from "./CreateOrderModal";

export interface AddOrderBtnProps {
  isFirstOrder: boolean;
}

export default function AddOrderBtn(props: AddOrderBtnProps) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    // Reset instrument value to empty so that the menu displays 'Select' by default instead of defaulting to the
    // option that was selected when the dialog was last opened.
    // setUnderlying("");
    // // Reset direction
    // setDirection("");
    // // Similarly resets order quantity to default
    // setQuantity(null);
    // isDefaultQuantity.current = true;
    setOpen(true);
  };

  return (
    <>
      {props.isFirstOrder ? (
        <Button
          variant="contained"
          color="dark"
          onClick={handleClickOpen}
          sx={{ mt: 2, borderRadius: 2, fontSize: 18, fontWeight: 500 }}
          startIcon={<AddCircleOutlineOutlinedIcon sx={{ height: 22, width: 22 }} />}
          data-testid="add-parent-order"
        >
          Add order
        </Button>
      ) : (
        <Tooltip
          title="Add order manually"
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: themeColors.white.secondary,
                color: themeColors.black.primary,
                fontSize: 10,
                fontWeight: 600,
                boxShadow: 2,
              },
            },
          }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            },
          }}
        >
          <IconButton aria-hidden="false" onClick={handleClickOpen} sx={{ ml: 3 }}>
            <AddCircleOutlineOutlinedIcon sx={{ height: 34, width: 34 }} />
          </IconButton>
        </Tooltip>
      )}
      {open && <CreateOrderModal open={open} setOpen={setOpen} />}
    </>
  );
}
