import { InstrumentDTO } from "@/redux/instrumentsApiSlice";

interface LivePrice {
  ceiling: number;
  floor: number;
}

const mockedLivePrice = (instrument: InstrumentDTO | null): LivePrice => {
  if (!instrument) {
    return {
      ceiling: 0,
      floor: 0,
    };
  }
  const instrumentId = instrument.instrument_id;
  if (instrumentId === "VGH5VGM5") {
    return {
      ceiling: -27.75,
      floor: -28.0,
    };
  }

  if (instrumentId === "CAH5CAM5") {
    return {
      ceiling: 0.56,
      floor: 0.54,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrumentId === "OEH5OEM5") {
    return {
      ceiling: -1.28,
      floor: -1.29,
    };
  }

  // TODO: Should handle 4 numbers after the decimal point.
  if (instrumentId === "SXOH5M5") {
    return {
      ceiling: -3.04,
      floor: -3.06,
    };
  }

  if (instrumentId === "DUH5DUM5") {
    return {
      ceiling: -0.41,
      floor: -0.415,
    };
  }

  if (instrumentId === "FSMI-H5M5") {
    return {
      ceiling: -38,
      floor: -39,
    };
  }

  if (instrumentId === "G-H5G-M5") {
    return {
      ceiling: -0.27,
      floor: -0.28,
    };
  }

  if (instrumentId === "RXH5RXM5") {
    return {
      ceiling: 0.12,
      floor: 0.11,
    };
  }

  if (instrumentId === "OEH5OEM5") {
    return {
      ceiling: -1.28,
      floor: -1.29,
    };
  }

  if (instrumentId === "IKH5IKM5") {
    return {
      ceiling: 0.07,
      floor: 0.06,
    };
  }

  if (instrumentId === "OATH5M5") {
    return {
      ceiling: -0.03,
      floor: -0.04,
    };
  }

  if (instrumentId === "BTSH5M5") {
    return {
      ceiling: -0.69,
      floor: -0.7,
    };
  }

  if (instrumentId === "UBH5UBM5") {
    return {
      ceiling: -0.1,
      floor: -0.12,
    };
  }
  return {
    ceiling: 0,
    floor: 0,
  };
};

export default mockedLivePrice;
