import { themeColors } from "@/app/theme";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Shadows,
  Typography,
  useMediaQuery,
  ZIndex,
} from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import Logo from "/favicon.svg";

// Styled component for the initial pop-up
const PrimaryCookieBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(5),
  right: theme.spacing(5),
  maxWidth: "800px",
  width: "800px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    right: 0,
    bottom: 0,
    borderRadius: 0,
  },
  [theme.breakpoints.down(800)]: {
    width: "100%",
    maxWidth: "100%",
  },
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: (theme.shadows as Shadows)[3],
  padding: theme.spacing(2),
  backgroundColor: themeColors.popup.backgroundColor,
  zIndex: (theme.zIndex as ZIndex).snackbar,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

// Styled component for the preferences pop-up
const SecondaryCookieOptionsBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(5),
  right: theme.spacing(5),
  maxWidth: "500px",
  width: "500px",
  padding: theme.spacing(2),
  backgroundColor: themeColors.popup.backgroundColor,
  borderRadius: theme.shape.borderRadius * 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const CustomCheckbox = styled(Checkbox)(() => ({
  "& .MuiSvgIcon-root": { fontSize: 20 },
  "color": themeColors.active.primary,
  "&.Mui-checked": {
    color: themeColors.active.primary,
  },
}));

const CookieConsent = () => {
  const [open, setOpen] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    essential: true,
    functional: true,
    performance: true,
    datetime: new Date().toISOString(),
  });
  const isSmallerThan800px = useMediaQuery("(max-width:800px)");

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", JSON.stringify(preferences));
    setOpen(false);
  };

  const handleManage = () => {
    setShowPreferences(true);
  };

  const handleTogglePref = (prefKey: keyof typeof preferences) => () => {
    setPreferences((prevPrefs) => ({ ...prevPrefs, [prefKey]: !prevPrefs[prefKey] }));
  };

  const handleSavePreferences = () => {
    localStorage.setItem("cookieConsent", JSON.stringify(preferences));
    setShowPreferences(false);
    setOpen(true); // Show initial popup after saving preferences
  };

  const handleAcceptAll = () => {
    const allPrefs = { essential: true, functional: true, performance: true, datetime: new Date().toISOString() };
    setPreferences(allPrefs);
    localStorage.setItem("cookieConsent", JSON.stringify(Object.keys(allPrefs)));
    setOpen(false);
    setShowPreferences(false);
  };

  const handleClosePreferences = () => {
    setShowPreferences(false);
  };

  function isAllPrefsSelected() {
    const allPrefs = ["essential", "functional", "performance", "datetime"];
    const selectedPrefs = Object.keys(preferences).filter((pref) => preferences[pref as keyof typeof preferences]);
    return selectedPrefs.length === allPrefs.length;
  }

  return (
    <>
      {open && !showPreferences && (
        <PrimaryCookieBox>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box flex="1">
              <Typography variant="h4" paragraph>
                Your Privacy <img style={{ height: 17, paddingLeft: 5 }} src={Logo} alt="InTick Logo" />
              </Typography>
              <Typography variant="h5Light" paragraph>
                We use cookies and similar technologies to help personalise content, tailor and measure ads, and provide
                a better experience. By clicking OK or turning an option in manage, you agree to this, as outlined in
                our{" "}
                <Link href="https://www.intick.com/cookies" target="_blank" rel="noopener">
                  Cookie Policy
                </Link>
                . To change preferences or withdraw consent, please update your in manage your cookie preferences.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection={isSmallerThan800px ? "column" : "row"}
              alignItems={isSmallerThan800px ? "flex-start" : "center"}
              justifyContent="center"
              pt={isSmallerThan800px ? 2 : 0}
              ml={isSmallerThan800px ? 0 : 2}
            >
              {!isSmallerThan800px && (
                <>
                  <Button
                    data-testid="cookies-manage"
                    variant="outlined"
                    color="secondary"
                    onClick={handleManage}
                    sx={{
                      "marginBottom": isSmallerThan800px ? 1 : 0,
                      "fontSize": "14px",
                      "boxShadow": "none",
                      "color": themeColors.black.primary,
                      "border": `1px solid ${themeColors.black.primary}`,
                      "&:hover": {
                        bgcolor: themeColors.popup.backgroundColor,
                        border: `1px solid ${themeColors.black.primary}`,
                      },
                    }}
                  >
                    Manage
                  </Button>
                  <Button
                    data-testid="cookies-accept"
                    variant="contained"
                    onClick={handleAccept}
                    sx={{
                      "bgcolor": themeColors.success.primary,
                      "&:hover": {
                        bgcolor: themeColors.success.primary,
                      },
                      "marginLeft": isSmallerThan800px ? 0 : 1,
                      "fontSize": "14px",
                      "boxShadow": "none",
                    }}
                  >
                    Accept
                  </Button>
                </>
              )}
            </Box>
          </Box>
          {isSmallerThan800px && (
            <Box display="flex" justifyContent="space-evenly" width="100%" mt={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleManage}
                sx={{
                  fontSize: "14px",
                  width: "30%",
                  boxShadow: "none",
                }}
              >
                Manage
              </Button>
              <Button
                variant="contained"
                onClick={handleAccept}
                sx={{
                  "bgcolor": themeColors.success.primary,
                  "&:hover": {
                    bgcolor: themeColors.success.primary,
                  },
                  "fontSize": "14px",
                  "width": "30%",
                  "boxShadow": "none",
                }}
              >
                Accept
              </Button>
            </Box>
          )}
        </PrimaryCookieBox>
      )}
      {showPreferences && (
        <SecondaryCookieOptionsBox>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="h4Light" paragraph>
              Your Cookie Preferences
            </Typography>
            <IconButton onClick={handleClosePreferences}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h5Light" paragraph>
            We use cookies to improve your experience on this website. You may choose which types of cookies to allow
            and change your preferences at any time. Disabling cookies may impact your experience on this website. You
            can learn more by viewing our{" "}
            <Link href="https://www.intick.com/cookies" target="_blank" rel="noopener">
              Cookie Policy
            </Link>
            .{" "}
          </Typography>
          <FormControlLabel
            control={<CustomCheckbox checked={preferences.essential} onChange={handleTogglePref("essential")} />}
            label="Essential Cookies"
            sx={{
              "& .MuiTypography-root": {
                fontSize: 14,
                fontWeight: "bold",
              },
            }}
          />
          <Typography variant="bodyLight" paragraph>
            Cookies required to enable basic website functionality.
          </Typography>
          <FormControlLabel
            control={<CustomCheckbox checked={preferences.functional} onChange={handleTogglePref("functional")} />}
            label="Functional Cookies"
            sx={{
              "& .MuiTypography-root": {
                fontSize: 14,
                fontWeight: "bold",
              },
            }}
          />
          <Typography variant="h5Light" paragraph>
            Cookies that are used to enhance the functionality of the website.
          </Typography>
          <FormControlLabel
            control={<CustomCheckbox checked={preferences.performance} onChange={handleTogglePref("performance")} />}
            label="Performance Cookies"
            sx={{
              "& .MuiTypography-root": {
                fontSize: 14,
                fontWeight: "bold",
              },
            }}
          />
          <Typography variant="h5Light" paragraph>
            Cookies used to understand how the website is being used.
          </Typography>
          <Box display="flex" justifyContent="center" width="100%" mt={2}>
            <Button
              data-testid="cookies-save-prefs"
              variant="contained"
              onClick={handleSavePreferences}
              sx={{
                "bgcolor": themeColors.popup.backgroundColor,
                "color": themeColors.black.primary,
                "border": `1px solid ${themeColors.black.primary}`,
                "&:hover": {
                  bgcolor: themeColors.popup.backgroundColor,
                  border: `1px solid ${themeColors.success.secondary}`,
                },
                "width": "35%",
                "fontSize": "14px",
                "boxShadow": "none",
              }}
            >
              Save Preferences
            </Button>
            <Box width="4%" />
            <Button
              data-testid="cookies-accept-all"
              variant="contained"
              disabled={!isAllPrefsSelected()}
              onClick={handleAcceptAll}
              sx={{
                "bgcolor": themeColors.success.primary,
                "&:hover": {
                  bgcolor: themeColors.success.primary,
                },
                "width": "35%",
                "fontSize": "14px",
                "boxShadow": "none",
              }}
            >
              Accept All Cookies
            </Button>
          </Box>
        </SecondaryCookieOptionsBox>
      )}
    </>
  );
};

export default CookieConsent;
