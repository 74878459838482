import { themeColors } from "@/app/theme";
import ProfileMenu from "@/features/Dashboard/ProfileMenu";
import Ticker from "@/features/Dashboard/Ticker";
import NotificationsMenu from "@/features/Notifications/components/NotificationsMenu";
import { selectAuthUser } from "@/redux/authSlice";
import { useAppSelector } from "@/store/hooks";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import Logo from "/logo.svg";

// const drawerWidth = 240;

export interface AppBarProps {
  dashboard: boolean;
}

export default function AppBar(props: AppBarProps) {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [isUserExpanded, setIsUserExpanded] = useState(true);
  const user = useAppSelector(selectAuthUser);

  // const handleUserClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //   event.stopPropagation();
  //   setIsUserExpanded(!isUserExpanded);
  // };

  // const handleDrawerToggle = () => {
  //   setIsDrawerOpen((prevState) => !prevState);
  // };

  const navigate = useNavigate();

  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
  //     <Box sx={{ p: 1 }}>
  //       <img style={{ height: 48 }} src={Logo} alt="InTick Logo" />
  //     </Box>
  //     <Divider />
  //     <List>
  //       {/* Dashboard */}
  //       <ListItemButton onClick={() => navigate("/dashboard")}>
  //         <ListItemIcon>
  //           <SpaceDashboardIcon />
  //         </ListItemIcon>
  //         <ListItemText primary="Dashboard" />
  //       </ListItemButton>

  //       {/* User */}
  //       <ListItemButton onClick={handleUserClick}>
  //         <ListItemIcon>
  //           <PersonIcon />
  //         </ListItemIcon>
  //         <ListItemText primary="User" />
  //         {!isUserExpanded ? <ExpandLess /> : <ExpandMore />}
  //       </ListItemButton>
  //       {/* User Dropdown */}
  //       <Collapse in={!isUserExpanded} timeout="auto" unmountOnExit>
  //         <List component="div" disablePadding>
  //           <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/user/account")}>
  //             <ListItemIcon>
  //               <ArticleIcon />
  //             </ListItemIcon>
  //             <ListItemText primary="Account Info" />
  //           </ListItemButton>
  //         </List>
  //       </Collapse>
  //     </List>
  //   </Box>
  // );

  // const container = window !== undefined ? window.document.body : undefined;

  return (
    <MuiAppBar sx={{ backgroundColor: themeColors.white.primary }} elevation={1}>
      <Toolbar>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent={"space-between"}
          sx={{ width: "100%", height: 109 }}
        >
          {/* Removed the side drawer button at request of James G. / Sarah / Patrick. */}
          {/* <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              cursor: "pointer",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <img style={{ height: 77 }} src={Logo} alt="InTick Logo" />
          </Box>
          <Stack alignItems={"center"} direction="row" spacing={10}>
            {user && props.dashboard && <Ticker />}
            {user && (
              <Box>
                <NotificationsMenu />
                <ProfileMenu />
              </Box>
            )}
          </Stack>
        </Stack>
      </Toolbar>
      {/* <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav> */}
    </MuiAppBar>
  );
}
