import z from "zod";

export const schema = z.object({
  email: z
    .string()
    .email({ message: "Email is required" })
    .min(5, { message: "Email must be at least 5 characters long" }),
  password: z
    .string({ message: "Password is required" })
    .min(10, { message: "Password must be at least 10 characters long" }),
  rememberMe: z.boolean(),
});
export type FormData = z.infer<typeof schema>;

export const defaultValues = {
  email: "",
  password: "",
  rememberMe: true,
};
