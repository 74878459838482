import { useGetCompaniesQuery } from "@/redux/companiesApiSlice";
import { useGetUsersQuery } from "@/redux/usersApiSlice";
import Stack from "@mui/material/Stack";
import CreateUserForm from "./CreateUserForm";
import UsersTable from "./UsersTable";

export default function CreateCompany() {
  const { data: companiesData } = useGetCompaniesQuery();
  const { data: usersData } = useGetUsersQuery();

  return (
    <Stack sx={{ py: 3, px: 3 }} spacing={3} width="100%" alignItems="center">
      {companiesData && <CreateUserForm companies={companiesData} />}
      {usersData && <UsersTable data={usersData} />}
    </Stack>
  );
}
