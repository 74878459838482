import AppBar from "@/app/router/components/AppBar";
import ContactFooter from "@/app/router/components/ContactFooter";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";
import ChangePasswordForm from "./ChangePasswordForm";

export default function AccountActivate() {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash") || "";

  return (
    <Stack spacing={20} alignItems="center">
      <AppBar dashboard={false} />
      <ChangePasswordForm hash={hash} />
      <ContactFooter />
    </Stack>
  );
}
