import { useBreakpoint } from "@/hooks/useBreakpoint";
import { useCancelMatchMutation } from "@/redux/matchApiSlice";
import { selectOrderMatchRequests } from "@/redux/matchSlice";
import { OrderDTO, OrderState, useDeleteOrderMutation } from "@/redux/ordersApiSlice";
import { useAppSelector } from "@/store/hooks";
import ClearIcon from "@mui/icons-material/Clear";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";

export interface CancelButtonProps {
  data: OrderDTO;
}

export default function CancelButton(props: CancelButtonProps) {
  const { isSmallOrUp } = useBreakpoint();
  const [isConfirming, setIsConfirming] = useState(false);

  const orderMatchRequests = useAppSelector(selectOrderMatchRequests);
  const [deleteOrder, { isLoading: isLoadingDelete }] = useDeleteOrderMutation();

  const [cancelMatchRequest, { isLoading: isLoadingCancel }] = useCancelMatchMutation();

  const handleClickConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsConfirming(!isConfirming);
  };

  const handleClickCancelOrder = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      if (props.data.state === OrderState.Working) {
        await deleteOrder(props.data.order_id).unwrap();
      } else if (props.data.state === OrderState.AwaitingMatch) {
        const orderMatchRequest = orderMatchRequests.find((x) => x.requesting_order_id === props.data.order_id);
        if (orderMatchRequest) {
          await cancelMatchRequest(orderMatchRequest.order_match_request_id).unwrap();
        }
      }
    } catch (error) {
      console.log("[ CancelButton ] handleClickCancelOrder Error: ", error);
    }
  };

  const label = isSmallOrUp ? "Cancel this order" : "Cancel";
  return !isConfirming ? (
    <Button
      startIcon={<ClearIcon sx={{ height: 12, width: 12, marginRight: -0.15 }} />}
      color="error"
      sx={{ height: 25, fontSize: isSmallOrUp ? 10 : 8, padding: 0 }}
      onClick={handleClickConfirm}
    >
      {label}
    </Button>
  ) : (
    <Stack direction="row" alignItems="center" spacing={0.5} height={25}>
      <Typography variant="captionLight">Are you sure?</Typography>
      <LoadingButton
        loading={isLoadingCancel || isLoadingDelete}
        sx={{ height: 20, minWidth: 24, fontSize: 10 }}
        color="error"
        onClick={handleClickCancelOrder}
      >
        Yes
      </LoadingButton>
      <Typography variant="captionLight">/</Typography>
      <Button
        disabled={isLoadingCancel || isLoadingDelete}
        sx={{ height: 20, minWidth: 24, fontSize: 10 }}
        onClick={handleClickConfirm}
      >
        No
      </Button>
    </Stack>
  );
}
