// import AccordionDetails from "@mui/material/AccordionDetails";
import { themeColors } from "@/app/theme";
import { getDecimalScale } from "@/features/Summary/LimitPriceInput";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { selectInstrument } from "@/redux/instrumentSlice";
import { OrderDTO, OrderState, orderStateLabels } from "@/redux/ordersApiSlice";
import { useAppSelector } from "@/store/hooks";
import { metricFormatter } from "@/utils/String";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import CancelButton from "./CancelButton";
import Logo from "/favicon.svg";

export interface OrderCardProps {
  order: OrderDTO;
  index: number;
}

export default function MyOrderCard({ order, index }: OrderCardProps) {
  const instrument = useAppSelector(selectInstrument);
  const decimalScale = getDecimalScale(instrument?.tick_size);
  const { isSm, isMediumOrUp, isSmallOrUp } = useBreakpoint();
  return (
    <Paper
      elevation={4}
      sx={{
        "mt": index === 0 ? 0 : "-24px",
        "borderRadius": 3,
        "zIndex": index,
        "&:before": {
          display: "none",
        },
        "backgroundColor": themeColors.state[order.state],
        "color": order.state === OrderState.BrokerFilled ? themeColors.white.primary : themeColors.text.primary,
        "&:hover": {
          filter: "brightness(0.96)",
        },
      }}
    >
      <Stack sx={{ height: 104, px: isMediumOrUp ? 2 : isSm ? 1 : 0.4, py: 1, justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="row" spacing={1} width="100%" flexWrap="wrap">
            <Typography variant={isMediumOrUp ? "h2" : isSm ? "h3" : "h4"}>
              {metricFormatter(order.quantity, 1)}
            </Typography>
            <Typography variant={isMediumOrUp ? "h2Light" : isSm ? "h3Light" : "h4Light"}>at</Typography>
            <Typography variant={isMediumOrUp ? "h2Light" : isSm ? "h3Light" : "h4Light"}>
              {numericFormatter(String(order.limit_price), {
                fixedDecimalScale: true,
                decimalScale,
              })}
            </Typography>
          </Stack>

          {isMediumOrUp && <img style={{ height: 20 }} src={Logo} alt="InTick Logo" />}
        </Box>
        <Typography variant={isSmallOrUp ? "h5" : "body"}>{orderStateLabels[order.state]}</Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box>
            {[OrderState.Working, OrderState.AwaitingMatch].includes(order.state) ? (
              <CancelButton data={order} />
            ) : null}
          </Box>
        </Box>
      </Stack>
      {/* <AccordionDetails>
        <Stack sx={{ flex: 1 }}>
          <Box alignItems="center" justifyContent="space-between" display="flex">
            <Typography variant="bodyLight">Working on screen</Typography>
            <Typography variant="bodyLight">Manual replace</Typography>
          </Box>
        </Stack>
      </AccordionDetails> */}
    </Paper>
  );
}
