import AppBar from "@/app/router/components/AppBar";
import StickyFooter from "@/app/router/components/StickyFooter";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NotFoundIcon from "/notfound.svg";

/**
 * This component will be displayed whenever the app is requested to render
 * an undefined route.
 */
export default function PageNotFound() {
  return (
    <Container>
      <Stack spacing={20}>
        <AppBar dashboard={false} />
        <Stack alignItems={"center"} spacing={4}>
          <img src={NotFoundIcon} style={{ width: "88px", height: "88px" }} alt="warning logo" />
          <Stack textAlign={"center"} spacing={1}>
            <Typography variant={"h4"}>Oops! Page Not Found</Typography>
            <Stack>
              <Typography>Looks like you've hit a dead-end. But don't worry,</Typography>
              <Typography>we're here to guide you back on track.</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <StickyFooter />
    </Container>
  );
}
