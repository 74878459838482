import { baseApi } from "@/config/BaseQuery";

export interface BrokerDTO {
  company_id: string;
  full_name: string;
  name: string;
}

export interface CompanyDTO {
  company_id: string;
  name: string;
  full_name: string;
  ems_identifier: string | null;
  account: string | null;
  roles: string[];
  brokers: BrokerDTO[];
  default_broker: BrokerDTO;
  created_at: string;
  updated_at: string;
}

export enum CompanyRole {
  RiskDesk = "risk-desk",
}

export interface PostCompanyPayload {
  name: string;
  full_name: string;
  trade_confirmation_email: string;
  roles: CompanyRole[];
  default_broker: string;
  broker_ids?: string[] | null;
  ems_identifier?: string | null;
  account?: string | null;
}

const companiesApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserCompany: build.query<CompanyDTO, string>({
      query: (companyId) => ({
        url: `/companies/${companyId}`,
        providesTags: ["Company"],
      }),
    }),
    getCompanies: build.query<CompanyDTO[], void>({
      query: () => "/companies",
      providesTags: ["Companies"],
    }),
    createCompany: build.mutation<CompanyDTO, PostCompanyPayload>({
      query: (body) => ({
        url: "/companies",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const { useGetUserCompanyQuery, useGetCompaniesQuery, useCreateCompanyMutation } = companiesApiSlice;
