import AppBar from "@/app/router/components/AppBar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";

export default function AccountInfo() {
  const navigate = useNavigate();

  return (
    <Stack spacing={20}>
      <AppBar dashboard={false} />
      <Stack flexDirection="row" alignItems="flex-start">
        <Profile />
        <Button sx={{ width: 196 }} onClick={() => navigate("/dashboard")}>
          Return to Dashboard
        </Button>
      </Stack>
    </Stack>
  );
}
