import { themeColors } from "@/app/theme";
import useTimer from "@/hooks/useTimer";
import { selectInstruments } from "@/redux/instrumentSlice";
import {
  getNotificationBody,
  getNotificationCaption,
  getNotificationTitle,
  getRfqDirection,
  isMatchRequest,
  isRfq,
  Notification,
  setActiveNotification,
} from "@/redux/notificationSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import dayjs from "@/utils/Time";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";
import { ListItemType } from "./NotificationsTab";
import useNotificationMutations from "./useNotificationMutations";

export interface ListItemProps {
  type: ListItemType;
  notification: Notification;
  isFetching: boolean;
  refetch: () => void;
}

export default function NotificationsListItem(props: ListItemProps) {
  const dispatch = useAppDispatch();

  // 8 hours harccoded
  const { timer, isTimerDone } = useTimer(props.notification !== null ? props.notification.expires_in_seconds : 0);

  const { handleRejectMatch, isLoadingReject, handleDismissRfq, isLoadingDismiss, error } = useNotificationMutations();
  const instruments = useAppSelector(selectInstruments);
  const instrument = instruments.find((i) => i.instrument_id === props.notification?.instrument_id);
  if (props.notification === null) return null;

  const now = dayjs();
  const isHistoric = props.type === ListItemType.HISTORY;

  const handleRejectAction = async () => {
    if (isMatchRequest(props.notification)) {
      await handleRejectMatch(props.notification);
    } else if (isRfq(props.notification)) {
      await handleDismissRfq(props.notification);
    }
    props.refetch();
  };

  return (
    <Stack
      px={3}
      py={1}
      mb={1}
      sx={{
        backgroundColor: isHistoric ? themeColors.white.primary : themeColors.active.secondary,
      }}
    >
      {/* Badge, Title, Timer */}
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <CircleIcon color="success" sx={{ fontSize: 12 }} />
        <Typography variant="h5" sx={{ flex: 1 }}>
          {getNotificationTitle(props.notification, instrument)}
        </Typography>
        <AccessTimeIcon sx={{ fontSize: 14 }} />

        <Typography width={48}>{timer}</Typography>
      </Stack>

      {/* Message */}
      <Typography ml={2} mt={1} variant="bodyLight">
        {getNotificationBody(props.notification, instrument)}
      </Typography>

      {isRfq(props.notification) && (
        <Typography ml={2} mb={0.5} variant="bodyLight">
          {`Direction: ${getRfqDirection(props.notification).toLowerCase()}. Quantity: ${numericFormatter(
            props.notification.quantity.toString(),
            { thousandSeparator: true }
          )}.`}
        </Typography>
      )}

      {/* Caption & Date */}
      <Box ml={2} display="flex" justifyContent="space-between">
        <Typography variant="caption" color={error ? "error" : "textPrimary"}>
          {error || getNotificationCaption(props.notification)}
        </Typography>
        <Typography variant="caption">{now.format("DD/MM/YY")}</Typography>
      </Box>

      {/* Actions */}
      <Stack direction="row" spacing={1} mt={1} justifyContent="flex-end">
        <LoadingButton
          loading={isLoadingReject || isLoadingDismiss}
          size="small"
          disabled={isHistoric || isTimerDone}
          color="error"
          onClick={() => handleRejectAction()}
        >
          <Typography variant="h5">Reject</Typography>
        </LoadingButton>
        <Button
          size="small"
          disabled={isHistoric || isTimerDone}
          color="success"
          endIcon={<ChevronRightIcon />}
          onClick={() => dispatch(setActiveNotification(props.notification))}
        >
          <Typography variant="h5">View</Typography>
        </Button>
      </Stack>
    </Stack>
  );
}
