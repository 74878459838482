import { ParentOrderDTO } from "@/redux/parentApiSlice";

export const ORDERS_PER_PAGE = 3;

const selectFirstVisibleOrdersIndices = (orders: ParentOrderDTO[]) => {
  const indices = Object.keys(orders).map((index) => parseInt(index));
  const firstThree = indices.slice(0, 3);
  return firstThree;
};

const selectVisibleOrdersIndices = (orders: ParentOrderDTO[]) => {
  const indices = Object.keys(orders).map((index) => parseInt(index));
  const lastThree = indices.slice(Math.max(indices.length - 3, 0));
  return lastThree;
};
const selectVisibleOrders = (orders: ParentOrderDTO[], indices: number[]): ParentOrderDTO[] => {
  if (indices.length === 0) return [];
  return indices.map((index) => orders[index] as ParentOrderDTO);
};

const getPagesCount = (orders: ParentOrderDTO[]) => Math.ceil(orders.length / ORDERS_PER_PAGE);

const goBack = (indices: number[]) => {
  const previousIndices = indices.reduce((acc, cur) => {
    const newIndex = Math.max(0, cur - ORDERS_PER_PAGE);
    if (acc.includes(newIndex)) {
      acc.push(acc.length);
    } else {
      acc.push(newIndex);
    }
    return acc;
  }, [] as number[]);
  return previousIndices;
};

const goForward = (currentIndices: number[], orders: ParentOrderDTO[]) => {
  const orderIndices = Object.keys(orders).map((index) => parseInt(index));
  const newIndex = (currentIndices[2] as number) + 3;
  let newList: number[] = [];
  if (orderIndices.includes(newIndex)) {
    const start = currentIndices[0] as number;
    newList = [start + 3, start + 4, start + 5];
  } else {
    const last = orderIndices.length;
    newList = [last - 3, last - 2, last - 1];
  }
  return newList;
};

export {
  getPagesCount,
  goBack,
  goForward,
  selectFirstVisibleOrdersIndices,
  selectVisibleOrders,
  selectVisibleOrdersIndices,
};
