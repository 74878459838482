import AppBar from "@/app/router/components/AppBar";
import ContactFooter from "@/app/router/components/ContactFooter";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";
import AccountActivateForm from "./AccountActivateForm";

export default function AccountActivate() {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash") || "";
  // const isExecutingBrokerClient = searchParams.get("isEBClient") === "true";

  return (
    <Stack spacing={20} alignItems="center">
      <AppBar dashboard={false} />
      <AccountActivateForm hash={hash} showTerms />
      <ContactFooter />
    </Stack>
  );
}
