import { themeColors } from "@/app/theme";
import FlagIcon from "@/components/FlagIcon";
import { selectInstrument, selectInstruments, setInstrument } from "@/redux/instrumentSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";

export default function InstrumentSelect() {
  const dispatch = useAppDispatch();

  const selectedInstrument = useAppSelector(selectInstrument);
  const instruments = useAppSelector(selectInstruments);

  const [selectValue, setSelectValue] = useState(selectedInstrument?.instrument_id || "");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectValue(event.target.value);
    const instrument = instruments.find((i) => i.instrument_id === event.target.value);
    dispatch(setInstrument(instrument || null));
  };

  return (
    <FormControl>
      <Select
        labelId="instrument-select-label"
        id="instrument-select"
        size="small"
        displayEmpty
        value={selectValue}
        onChange={handleChange}
        sx={{
          width: 256,
          borderRadius: 2,
          backgroundColor: themeColors.white.primary,
        }}
      >
        <MenuItem value={""}>
          <Typography variant="h5Light">Select to view instruments</Typography>
        </MenuItem>
        {instruments.map((instrument) => (
          <MenuItem value={instrument.instrument_id} key={instrument.instrument_id}>
            <Stack direction="row" spacing={1} alignItems="center">
              <FlagIcon width={18} height={24} instrumentId={instrument.instrument_id} />
              <Typography
                variant="bodyLight"
                noWrap
                sx={{
                  width: 180,
                }}
              >
                {instrument.name} {instrument.term}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
