import ScrollBox from "@/app/router/components/ScrollBox";
import ErrorCard from "@/components/ErrorCard";
import OrderBookTickCard from "@/features/OrderBook/OrderBookTickCard";
import { selectInstrument } from "@/redux/instrumentSlice";
import { OrderDTO } from "@/redux/ordersApiSlice";
import { Direction } from "@/redux/parentApiSlice";
import { selectParent } from "@/redux/parentSlice";
import {
  Price,
  selectElectronicPrices,
  selectPrice,
  setPrice,
  setElectronicPrices as setStoreElectronicPrices,
  setPrices as setStorePrices,
} from "@/redux/pricesSlice";
import { RfqResponseDTO } from "@/redux/rfqApiSlice";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { range } from "lodash";
import { useEffect, useState } from "react";
import { getPrices } from "./getPrices";
import mockedLivePrice from "./mockedLivePrice";
import usePriceQueries from "./usePriceQueries";

export const selectOrdersAtPrice = (orders: (OrderDTO | RfqResponseDTO)[], price: Price) => {
  const sellsAtPrice =
    orders?.filter((order) => order.limit_price === price.value && order.direction === Direction.SELL) || [];
  const buysAtPrice =
    orders?.filter((order) => order.limit_price === price.value && order.direction === Direction.BUY) || [];
  return { sellsAtPrice, buysAtPrice };
};

const selectRfqResponsesAtPrice = (orders: (OrderDTO | RfqResponseDTO)[], price: Price) => {
  return orders?.filter((order) => order.limit_price === price.value && !order.direction) || [];
};

const OrderBook = () => {
  const dispatch = useAppDispatch();

  const selectedParent = useAppSelector(selectParent);
  const selectedPrice = useAppSelector(selectPrice);
  const selectedInstrument = useAppSelector(selectInstrument);
  // Generate prices based on either the offers + instrument stats,
  // working orders, or a manually set limit price.
  const selectedElectronicPrices = useAppSelector(selectElectronicPrices);

  const [activePrice, setActivePrice] = useState<number | null>(null);
  const [prices, setPrices] = useState<Price[]>([]);
  const [orderBookData, setOrderBookData] = useState<(OrderDTO | RfqResponseDTO)[]>([]);
  const [electronicPrices, setElectronicPrices] = useState(selectedElectronicPrices);

  const { ordersData, rfqResponsesData, ordersError, isErrorOrders, refetchOrders, getBestPrice, isLoadingOrders } =
    usePriceQueries();

  useEffect(() => {
    // Timeout is used to debounce the function. User can freely enter a limit
    // price via the SummaryBox, and we need to ensure that the function does
    // not execute between keystrokes.
    const generatePrices = setTimeout(() => {
      const priceInfo = getPrices(ordersData, rfqResponsesData, selectedPrice, selectedInstrument);
      setPrices(priceInfo.prices);
      dispatch(setStorePrices(priceInfo));
      setElectronicPrices(mockedLivePrice(selectedInstrument));

      if (selectedPrice !== activePrice) {
        // Ensures the price cards know the current active price.
        setActivePrice(selectedPrice);
      }
    }, 100);

    return () => clearTimeout(generatePrices);
  }, [ordersData, selectedPrice, rfqResponsesData]);

  useEffect(() => {
    dispatch(setStoreElectronicPrices(electronicPrices));
  }, [electronicPrices]);

  useEffect(() => {
    const a = ordersData?.length ? [...ordersData] : [];
    const b = rfqResponsesData?.length ? [...rfqResponsesData] : [];
    setOrderBookData([...a, ...b]);
  }, [rfqResponsesData, ordersData]);

  const onClickPrice = (value: number) => {
    const newPrice = activePrice === value ? null : value;
    dispatch(setPrice(newPrice)); // Inform the SummaryBox.
    setActivePrice(newPrice); // Inform OrderBookTickCard & OfferCard components.
  };

  if (isErrorOrders) {
    return <ErrorCard error={ordersError} refreshFn={refetchOrders} />;
  }

  if (isLoadingOrders) {
    return (
      <Stack spacing={2}>
        {range(5).map((i) => (
          <Skeleton key={i} height={120} variant="rounded" width="100%">
            <OrderBookTickCard
              price={{ value: 0, isElectronic: false }}
              orders={[]}
              isBest={false}
              onClick={onClickPrice}
              selectedPrice={activePrice}
            />
          </Skeleton>
        ))}
      </Stack>
    );
  }

  // Find the best bid and ask (if they exist).
  const bestAsk = getBestPrice(Direction.SELL, orderBookData || []);
  const bestBid = getBestPrice(Direction.BUY, orderBookData || []);
  const hasLiquidity = !!bestBid || !!bestAsk;
  return (
    <ScrollBox offset={!!selectedParent ? 4 : 12}>
      <Stack spacing={2} direction="column">
        {prices.map((price, i) => {
          // Find all the offers at this price.
          const { sellsAtPrice, buysAtPrice } = selectOrdersAtPrice(orderBookData, price);
          const rfqResponsesWithoutDirection = selectRfqResponsesAtPrice(orderBookData, price);
          // I.e. this price contains either the best ask or best bid.
          const isBestAsk = sellsAtPrice.some((order) => order.limit_price === bestAsk);
          const isBestBid = buysAtPrice.some((order) => order.limit_price === bestBid);
          const scrollScreenToThis = hasLiquidity && price.value === selectedPrice;
          return (
            <>
              {!!sellsAtPrice.length && (
                <OrderBookTickCard
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  isBest={isBestAsk}
                  scrollScreenToThis={scrollScreenToThis}
                  orders={sellsAtPrice}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {!!buysAtPrice.length && (
                <OrderBookTickCard
                  data-testid={`price-card-${price}`}
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  scrollScreenToThis={scrollScreenToThis}
                  isBest={isBestBid}
                  orders={buysAtPrice}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {!!rfqResponsesWithoutDirection.length && (
                <OrderBookTickCard
                  data-testid={`price-card-${price}`}
                  price={price}
                  isFirst={i === 0}
                  isLast={i === prices.length - 1}
                  scrollScreenToThis={scrollScreenToThis}
                  isBest={isBestBid}
                  orders={rfqResponsesWithoutDirection}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
              {buysAtPrice.length === 0 && sellsAtPrice.length === 0 && (
                <OrderBookTickCard
                  price={price}
                  isFirst={i === 0}
                  scrollScreenToThis={!hasLiquidity && i === 2}
                  isLast={i === prices.length - 1}
                  isBest={false}
                  orders={[]}
                  onClick={onClickPrice}
                  selectedPrice={activePrice}
                />
              )}
            </>
          );
        })}
      </Stack>
    </ScrollBox>
  );
};

export default OrderBook;
