import { createAppSlice } from "@/store/createAppSlice";
import { resetState } from "@/store/reset";
import type { PayloadAction } from "@reduxjs/toolkit";
import { OrderDTO } from "./ordersApiSlice";
import { RfqResponseDTO } from "./rfqApiSlice";

export interface Price {
  value: number;
  isElectronic: boolean;
}

export interface PriceInfo {
  prices: Price[];
  floor: number;
  ceiling: number;
  step: number;
}

export interface PricesSliceState {
  /**
   * The value of the currently selected price. null denotes deslection.
   */
  value: number | null;
  /**
   * An array of offers that have been selected.
   */
  items: (OrderDTO | RfqResponseDTO)[];

  priceInfo: PriceInfo;
  /**
   * Is the offers API response empty? I.e. is it a cold start condition.
   */
  isEmpty: boolean;
  /**
   * The current upper and lower bound of the price list.
   */
  electronicPrices: ElectronicPrices;
}

export interface ElectronicPrices {
  floor: number;
  ceiling: number;
}

const initialState: PricesSliceState = {
  value: null,
  priceInfo: { prices: [], floor: 0, ceiling: 0, step: 0 },
  items: [],
  isEmpty: true,
  electronicPrices: {
    floor: 0,
    ceiling: 0,
  },
};

export const isRfqResponse = (item: RfqResponseDTO | OrderDTO) => {
  return item !== null && "rfq_id" in item;
};

export const isOrder = (item: RfqResponseDTO | OrderDTO) => {
  return item !== null && "order_id" in item;
};

export const pricesSlice = createAppSlice({
  name: "prices",
  initialState,
  reducers: (create) => ({
    setPrice: create.reducer((state, action: PayloadAction<number | null>) => {
      state.value = action.payload;
    }),
    setPrices: create.reducer((state, action: PayloadAction<PriceInfo>) => {
      state.priceInfo = action.payload;
    }),
    addItem: create.reducer((state, action: PayloadAction<OrderDTO | RfqResponseDTO>) => {
      state.items.push(action.payload);
    }),
    removeItem: create.reducer((state, action: PayloadAction<OrderDTO | RfqResponseDTO>) => {
      let newItems: (OrderDTO | RfqResponseDTO)[] = [];
      if (isRfqResponse(action.payload)) {
        newItems = state.items.filter(
          (i: any) => i.rfq_response_id !== (action.payload as RfqResponseDTO).rfq_response_id
        );
      }
      if (isOrder(action.payload)) {
        // tslint:disable-next-line
        newItems = state.items.filter((i: any) => i.order_id !== (action.payload as OrderDTO).order_id);
      }
      state.items = newItems;
    }),
    resetItems: create.reducer((state) => {
      state.items = [];
    }),
    setIsEmpty: create.reducer((state, action: PayloadAction<boolean>) => {
      state.isEmpty = action.payload;
    }),
    setElectronicPrices: create.reducer((state, action: PayloadAction<ElectronicPrices>) => {
      state.electronicPrices = action.payload;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(resetState.type, (state) => {
      Object.assign(state, initialState);
    });
  },
  selectors: {
    selectPriceInfo: (state) => state.priceInfo,
    selectPrice: (state) => state.value,
    selectItems: (state) => state.items,
    selectIsEmpty: (state) => state.isEmpty,
    selectElectronicPrices: (state) => state.electronicPrices,
  },
});

export const { setPrices, setPrice, addItem, removeItem, resetItems, setIsEmpty, setElectronicPrices } =
  pricesSlice.actions;

export const { selectPriceInfo, selectPrice, selectItems, selectIsEmpty, selectElectronicPrices } =
  pricesSlice.selectors;
