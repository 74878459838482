import AppBar from "@/app/router/components/AppBar";
import LoginFooter from "@/features/Login/LoginFooter";
import { fetchUser, selectAuthStatus, selectAuthToken, selectAuthUser } from "@/redux/authSlice";
import { useAppDispatch } from "@/store/hooks";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import LoginForm from "./LoginForm";

export default function Login() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const redirect: string = location.state?.from?.pathname || undefined;

  // TODO review what redux is doing here
  const authToken = useSelector(selectAuthToken);
  const authStatus = useSelector(selectAuthStatus);
  const authUser = useSelector(selectAuthUser);
  useEffect(() => {
    if (authToken && !authUser && authStatus === "idle") {
      // Once the token has been received, fetch the user.
      dispatch(fetchUser(authToken));
    }
  }, [authToken, authUser, authStatus]);

  if (authToken && authUser && authStatus === "idle") {
    return <Navigate to={redirect ? (redirect !== "/login" ? redirect : "/dashboard") : "/dashboard"} />;
  }

  return (
    <Stack spacing={20} alignItems="center">
      <AppBar dashboard={false} />
      <LoginForm />
      <LoginFooter />
    </Stack>
  );
}
