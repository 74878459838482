import { themeColors } from "@/app/theme";
import usePriceQueries from "@/features/OrderBook/usePriceQueries";
import { Direction } from "@/redux/parentApiSlice";
import Stack from "@mui/material/Stack";
import PriceInfoCard from "./PriceInfoCard";

export default function Ticker() {
  const { ordersData, rfqResponsesData, getBestPrice } = usePriceQueries();
  const orders = ordersData?.length ? [...ordersData] : [];
  const rfqResponses = rfqResponsesData?.length ? [...rfqResponsesData] : [];
  const data = [...orders, ...rfqResponses];
  const totalQuantityBuy = data
    .map((order) => (order.direction === Direction.BUY ? order.quantity : 0))
    .reduce((cur, acc) => acc + cur, 0);

  const totalQuantitySell = data
    .map((order) => (order.direction === Direction.SELL ? order.quantity : 0))
    .reduce((cur, acc) => acc + cur, 0);

  const bestBuy = getBestPrice(Direction.BUY, data ?? []) ?? 0;
  const bestSell = getBestPrice(Direction.SELL, data ?? []) ?? 0;
  // Placeholder value until API connection is available
  // const lastTradePrice: number = 0;

  return (
    <>
      <Stack direction={"row"} spacing={3}>
        <PriceInfoCard
          title="Total Bid"
          quantity={totalQuantityBuy}
          price={bestBuy}
          color={themeColors.prices[Direction.BUY]}
        />

        <PriceInfoCard
          title="Total Ask"
          quantity={totalQuantitySell}
          price={bestSell}
          color={themeColors.prices[Direction.SELL]}
        />
      </Stack>
    </>
  );
}
