import { CH, DE, EU, FR, GB, IT } from "country-flag-icons/react/3x2";

export interface FlagIconProps {
  instrumentId: string;
  height: number;
  width?: number;
}

export default function FlagIcon(props: FlagIconProps) {
  if (!props.instrumentId) return null;

  switch (props.instrumentId) {
    case "VGH5VGM5":
    case "CAH5CAM5":
    case "SXOH5M5":
      return <EU width={props.width} height={props.height} />;
    case "RXH5RXM5":
    case "DUH5DUM5":
    case "UBH5UBM5":
    case "OEH5OEM5":
      return <DE width={props.width} height={props.height} />;
    case "OATH5M5":
      return <FR width={props.width} height={props.height} />;
    case "IKH5IKM5":
    case "BTSH5M5":
      return <IT width={props.width} height={props.height} />;
    case "FSMI-H5M5":
      return <CH width={props.width} height={props.height} />;
    case "G-H5G-M5":
      return <GB width={props.width} height={props.height} />;
    default:
      return null;
  }
}
