import { themeColors } from "@/app/theme";
import Typography from "@mui/material/Typography";

interface ErrorAlertProps {
  message: string | null;
}

const ErrorAlert = ({ message }: ErrorAlertProps) => {
  if (!message) {
    return null;
  }
  return (
    <Typography
      variant="body"
      sx={{
        color: themeColors.error.primary,
        position: "absolute",
        bottom: 4,
        right: 24,
      }}
    >
      {message}
    </Typography>
  );
};

export default ErrorAlert;
