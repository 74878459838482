import { themeColors } from "@/app/theme";
import { handlePasswordChange } from "@/redux/authSlice";
import { useAppDispatch } from "@/store/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { Fade, IconButton, InputAdornment } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { defaultValues, FormData, schema } from "./schema";

interface Props {
  hash: string;
}

export default function ChangePasswordForm({ hash }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const onSubmit = async (data: FormData) => {
    setApiErrorMessage("");
    const result = await dispatch(
      handlePasswordChange({
        password: data.password,
        hash,
      })
    );
    if (result.meta.requestStatus === "fulfilled") {
      setIsSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setIsSuccess(false);
      setApiErrorMessage("The password reset link is invalid or has expired. Please request a new link.");
    }
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };

  return (
    <Stack textAlign="center" spacing={3} width={400}>
      <Stack spacing={1}>
        <Typography fontWeight={500} variant="h4" color={themeColors.text.secondary}>
          Change your password
        </Typography>
      </Stack>
      <Fade in timeout={3000}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyUp={handleEnterPress}>
          <Stack alignItems={"center"} spacing={3}>
            <TextField
              {...register("password")}
              margin="normal"
              label="New Password"
              fullWidth
              error={!!errors.password}
              type={showPassword ? "text" : "password"}
              helperText={errors.password?.message}
              InputLabelProps={{ shrink: true }}
              size={"small"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color="secondary"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              label="Repeat Password"
              fullWidth
              {...register("confirmPassword")}
              error={!!errors.confirmPassword}
              type={showPassword ? "text" : "password"}
              helperText={errors.confirmPassword?.message}
              InputLabelProps={{ shrink: true }}
              size={"small"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color="secondary"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Stack alignItems="flex-end" width="100%">
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                size={"small"}
                variant="contained"
                color={isSuccess ? "success" : "rfqDark"}
                sx={{ fontSize: 16, px: 2.5 }}
              >
                {isSuccess ? <CheckCircleIcon /> : <Typography>Done</Typography>}
              </LoadingButton>
            </Stack>
          </Stack>
          {apiErrorMessage && (
            <Typography variant="caption" color={themeColors.error.primary}>
              {apiErrorMessage}
            </Typography>
          )}
        </form>
      </Fade>
    </Stack>
  );
}
