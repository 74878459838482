import DataGrid, { Column, DataGridHandle } from "react-data-grid";
import "react-data-grid/lib/styles.css";

import ErrorCard from "@/components/ErrorCard";
import { exportToCsv, exportToPdf } from "@/features/Admin/Billing/exportUtils";
import { BillingDTO, useGetBillingDataQuery } from "@/redux/billingApiSlice";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import { useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import AppBar from "../../../app/router/components/AppBar";

const columns: readonly Column<BillingDTO>[] = [
  { key: "company_name", name: "Company name" },
  { key: "first_name", name: "First name" },
  { key: "last_name", name: "Last name" },
  { key: "user_id", name: "User ID" },
  { key: "broker_name", name: "Broker name" },
  { key: "broker_id", name: "Broker ID" },
  { key: "match_request_id", name: "Match Request ID" },
  { key: "order_id", name: "Order ID" },
  { key: "payment_order_id", name: "Parent  ID" },
  { key: "creation_date", name: "Creation date" },
  { key: "creation_time", name: "Creation time (UTC)" },
  { key: "instrument_name", name: "Instrument" },
  { key: "bbg_code", name: "BBG code" },
  { key: "exchange_instrument_id", name: "Exchange instrument ID" },
  { key: "exchange", name: "Exchange" },
  { key: "exchange_iso_code", name: "Exchange ISO code" },
  { key: "tick_size", name: "Tick size" },
  { key: "term", name: "Term" },
  { key: "direction", name: "Direction" },
  { key: "quantity", name: "Quantity" },
  { key: "price", name: "Price" },
];

export default function Billing() {
  const { currentData, error, isError, isLoading, isSuccess } = useGetBillingDataQuery();
  const [isExporting, setIsExporting] = useState(false);
  const gridRef = useRef<DataGridHandle>(null);

  const [columnsOrder, setColumnsOrder] = useState((): readonly number[] => columns.map((_, index) => index));
  const reorderedColumns = useMemo(() => {
    return columnsOrder.map((index) => columns[index] as Column<BillingDTO>);
  }, [columnsOrder]);

  function onColumnsReorder(sourceKey: string, targetKey: string) {
    setColumnsOrder((columnsOrder) => {
      const sourceColumnOrderIndex = columnsOrder.findIndex((index) => columns[index]!.key === sourceKey);
      const targetColumnOrderIndex = columnsOrder.findIndex((index) => columns[index]!.key === targetKey);
      const sourceColumnOrder = columnsOrder[sourceColumnOrderIndex];
      const newColumnsOrder = columnsOrder.toSpliced(sourceColumnOrderIndex, 1);
      newColumnsOrder.splice(targetColumnOrderIndex, 0, sourceColumnOrder!);
      return newColumnsOrder;
    });
  }

  function handleExportToCsv() {
    flushSync(() => {
      setIsExporting(true);
    });
    exportToCsv(gridRef.current!.element!, "billing.csv");
    flushSync(() => {
      setIsExporting(false);
    });
  }

  async function handleExportToPdf() {
    flushSync(() => {
      setIsExporting(true);
    });
    await exportToPdf(gridRef.current!.element!, "billing.pdf");
    flushSync(() => {
      setIsExporting(false);
    });
  }

  if (isError) {
    return <ErrorCard error={error} />;
  }

  if (isLoading) {
    return <Skeleton height={96} width="100%" variant="rounded" />;
  }

  if (isSuccess) {
    const rows: BillingDTO[] = currentData || [];

    return (
      <>
        <AppBar dashboard={false} />
        <Grid component="main" container spacing={2} sx={{ mt: 14 }}>
          <Grid>
            <Button
              variant="contained"
              color="dark"
              type="button"
              sx={{ mt: 2, mr: 2, ml: 1, borderRadius: 2 }}
              onClick={handleExportToCsv}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="dark"
              type="button"
              sx={{ mt: 2, mr: 2, borderRadius: 2 }}
              onClick={handleExportToPdf}
            >
              Export to PDF
            </Button>
          </Grid>
          <Grid sx={{ ml: 1, mr: 1 }}>
            <DataGrid
              ref={gridRef}
              columns={reorderedColumns}
              rows={rows}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
                draggable: true,
              }}
              className="rdg-light"
              onColumnsReorder={onColumnsReorder}
              enableVirtualization={!isExporting}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
}
