import z from "zod";

export type FormData = z.infer<typeof schema>;
const schema = z
  .object({
    password: z
      .string()
      .min(10, { message: "Password must be at least 10 characters long" })
      .max(30, { message: "Password can not be longer than 30 characters" })
      .refine((password) => /[A-Z]/.test(password), {
        message: "Password must contain at least one uppercase letter",
      })
      .refine((password) => /[a-z]/.test(password), {
        message: "Password must contain at least one lowercase letter",
      })
      .refine((password) => /[0-9]/.test(password), { message: "Password must contain at least one numeric digit" })
      .refine((password) => /[!@#$%^&*]/.test(password), {
        message: "Password must contain at least one special character",
      }),
    confirmPassword: z.string().min(10).regex(new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"), {
      message: "Password must be at least 10 characters and contain an uppercase letter, lowercase letter, and number",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

const defaultValues = {
  password: "",
  confirmPassword: "",
};

export { defaultValues, schema };
