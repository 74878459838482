import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddOrderBtn from "./AddOrderBtn";

const GettingStarted = () => {
  return (
    <Stack spacing={1}>
      <Typography variant="h2">Getting Started</Typography>
      <Typography variant="h5Light">
        Load orders from your EMS by selecting "Intick," add
        <br />
        an order directly, or start trading to create an order.
      </Typography>
      <div>
        <AddOrderBtn isFirstOrder={true} />
      </div>
    </Stack>
  );
};

export default GettingStarted;
