import { baseApi } from "@/config/BaseQuery";
import { UserDTO } from "@/types/User";

export enum UserRole {
  Trader = "trader",
  IntickAdmin = "intick-admin",
  CompanyAdmin = "company-admin",
}

export interface PostUserPayload {
  company_id: string;
  email: string;
  roles: UserRole[];
  first_name: string;
  last_name: string;
  active: boolean;
  ems_id?: string | null;
}

const usersApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<UserDTO[], void>({
      query: () => "/users",
      providesTags: ["Users"],
    }),
    createUser: build.mutation<UserDTO, PostUserPayload>({
      query: (body) => ({
        url: "/users",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const { useGetUsersQuery, useCreateUserMutation } = usersApiSlice;
