import { themeColors } from "@/app/theme";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { OrderState } from "@/redux/ordersApiSlice";
import { ParentOrderDTO } from "@/redux/parentApiSlice";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { numericFormatter } from "react-number-format";

export interface MoreDetailsProps {
  parent: ParentOrderDTO;
}

export default function MoreDetails(props: MoreDetailsProps) {
  const { isLargeOrUp } = useBreakpoint();

  const tradeable = (props.parent.quantities.tradable || 0).toString();

  const working = (props.parent.quantities.working || "---").toString();

  const matched = (props.parent.quantities.matched || "---").toString();

  const chipStyles = {
    "fontSize": "10px",
    "fontWeight": "600",
    "paddingRight": 0,
    "width": 75,
    "height": "22px",
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "& .MuiChip-label": {},
  };

  return (
    <Stack direction="row" spacing={isLargeOrUp ? 1 : 0.2}>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Remaining" sx={{ ...chipStyles, backgroundColor: themeColors.border }} />
        <Typography variant="h5">{numericFormatter(String(tradeable), { thousandSeparator: "," })}</Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Working" sx={{ ...chipStyles, backgroundColor: themeColors.state[OrderState.Working] }} />
        <Typography variant="h5">{numericFormatter(String(working), { thousandSeparator: "," })}</Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <Chip label="Matched" sx={{ ...chipStyles, backgroundColor: themeColors.state[OrderState.Matched] }} />
        <Typography variant="h5">{numericFormatter(String(matched), { thousandSeparator: "," })}</Typography>
      </Stack>
    </Stack>
  );
}
