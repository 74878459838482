import { UserDTO } from "@/types/User";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

interface RowData {
  email: string;
  first_name: string;
  last_name: string;
  user_id: string;
  company_id: string;
  roles: string;
  active: string;
  verified: string;
  created_at: string;
  updated_at: string;
}

const columnDefs = [
  { field: "email" },
  { field: "first_name" },
  { field: "last_name" },
  { field: "user_id" },
  { field: "company_id" },
  { field: "roles" },
  { field: "active" },
  { field: "verified" },
  { field: "created_at" },
  { field: "updated_at" },
] as ColDef<RowData>[];

const selectRowData = (users: UserDTO[]) =>
  users.map((user) => ({
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name || "",
    user_id: user.user_id,
    company_id: user.company_id,
    roles: user.roles ? user.roles.map((r) => r.toString()).join(", ") : "",
    active: user.active ? "Yes" : "No",
    verified: user.verified ? "Yes" : "No",
    created_at: user.created_at,
    updated_at: user.updated_at,
  }));

interface Props {
  data: UserDTO[];
}

const UsersTable = ({ data }: Props) => {
  return (
    <Stack sx={{ height: 1000, width: "100%" }} spacing={1}>
      <Typography variant="h3">Users</Typography>
      <AgGridReact rowData={selectRowData(data)} columnDefs={columnDefs} />
    </Stack>
  );
};

export default UsersTable;
