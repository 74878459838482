import ErrorCard from "@/components/ErrorCard";
import { useGetMyOrdersQuery } from "@/redux/ordersApiSlice";
import { selectParent } from "@/redux/parentSlice";
import { useAppSelector } from "@/store/hooks";
import { refetchTimeouts } from "@/utils/Constants";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import MyOrderCard from "../MyOrderCard";

const MyOrdersList = () => {
  const selectedParent = useAppSelector(selectParent);

  // Only show this component if there is a selected parent order.
  const {
    currentData: myOrders,
    error,
    isError,
    isLoading,
    refetch,
  } = useGetMyOrdersQuery(selectedParent?.parent_order_id || "", {
    skip: !selectedParent,
    pollingInterval: refetchTimeouts.fast,
  });

  if (isError) {
    return <ErrorCard error={error} refreshFn={refetch} />;
  }

  if (isLoading) {
    return <Skeleton height={120} width="100%" variant="rounded" />;
  }

  if (myOrders) {
    return (
      <Stack spacing={-1}>
        {myOrders.map((order, i) => (
          <MyOrderCard key={order.order_id} index={i} order={order} />
        ))}
      </Stack>
    );
  }

  return null;
};

export default MyOrdersList;
